import { useMutation, useQuery } from '@apollo/react-hooks'
import { QueryResult } from '@apollo/react-common'
import { REPORT_BUG } from './mutation'
import React from 'react'
import { ApolloError } from 'apollo-client'
import { ReportBug } from './types/ReportBug'
import { ReportBugInput } from './../../types/globalTypes'

// export const useGetMe: () => [
//   GetMe_me | undefined,
//   Pick<QueryResult<GetMe>, 'loading' | 'error'>,
// ] = () => {
//   const { data, loading, error } = useQuery<GetMe>(GET_ME)
//   return [data?.me, { loading, error }]
// }

export const useBugReport = () => {
    const [doBugReport, result] = useMutation<
      ReportBug
    >(REPORT_BUG)
  
    const mutate = (input: ReportBugInput) => {
        doBugReport({ variables: { input } }).catch((error) => {
        const { graphQLErrors } = error
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
          const message = graphQLErrors[0].message
          console.error(message)
        }
      })
    }
  
    return { mutate, result }
  }

// export const usePasswordRecover: (
//   onError: (error: ApolloError) => void,
// ) => [
//   (input: ResetPasswordVariables) => void,
//   { loading: boolean; error: string },
// ] = (onError) => {
//   const [error, setError] = React.useState<string>('')
//   const [doPasswordRecover, { loading }] = useMutation<
//     ResetPassword,
//     ResetPasswordVariables
//   >(RESET_PASSWORD)

//   const mutate = (input: ResetPasswordVariables) => {
//     setError('')
//     doPasswordRecover({ variables: { ...input } }).catch((error) =>
//       onError(error),
//     )
//   }

//   return [mutate, { loading, error }]
// }

// export const useTokenCheck: (
//   onError: (error: ApolloError) => void,
// ) => [
//   (input: CheckTokenVariables) => void,
//   { loading: boolean; error: string },
// ] = (onError) => {
//   const [error, setError] = React.useState<string>('')
//   const [doTokenChecke, { loading }] = useMutation<
//     CheckToken,
//     CheckTokenVariables
//   >(CHECK_TOKEN)

//   const mutate = (input: CheckTokenVariables) => {
//     setError('')
//     doTokenChecke({ variables: { ...input } }).catch((error) => onError(error))
//   }

//   return [mutate, { loading, error }]
// }
