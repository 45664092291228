import { UnityMessagingData } from '../UnityDatascience' // Assuming UnityMessagingData is the interface or type containing the unity-related data

const retrieveSceneTarget = (unityMessagingData: UnityMessagingData) => {
  const sceneTarget = localStorage.getItem('sceneTarget') || ''
  const { sendMessage } = unityMessagingData

  console.log('Retrieve')
  console.log('unityMessagingData')
  console.log(unityMessagingData)
  console.log(sceneTarget)
  console.log('Retrieve end')

  sendMessage('ReactLink', 'ReceiveTransitionTarget', sceneTarget)
  console.log('Retrieve sent')
}

export default retrieveSceneTarget
