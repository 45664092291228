import { IS_LOGGED_IN } from 'domains/user/query'

const setLogInStatus = async (data, _args, { cache }) => {
  if (data.login?.accessToken || data.signup?.accessToken || data.sso?.accessToken) {
    localStorage.setItem(
      'token',
      data.login?.accessToken || data.signup?.accessToken || data.sso?.accessToken,
    )
    cache.writeQuery({
      query: IS_LOGGED_IN,
      data: {
        isLoggedIn: true,
      },
    })

    return true
  }

  return false
}

const logout = async (_data, _args, { client }) => {
  await client.resetStore()

  return true
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { setLogInStatus, logout }
