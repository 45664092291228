import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableProps,
} from 'mui-datatables'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { useGetUsers } from '../../../domains/user/useUser'
import AddUser from './AddUser'
import DeleteUserDialog from './DeleteUserDialog'

const useStyles = makeStyles((theme) => ({
  addFab: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(3),
  },
}))

const UsersList: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const match = useRouteMatch()
  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false)
  const [deleteUserId, setDeleteUserId] = React.useState('')

  const [users, { loading: getUsersQueryLoading }] = useGetUsers()

  let tableData: MUIDataTableProps['data'] = []

  const onViewUserClick: (
    userId: string,
  ) => React.MouseEventHandler<HTMLButtonElement> = (userId) => (e) => {
    history.push(`${match.path}${userId}`)
  }

  const options = {
    selectableRows: 'none' as const,
    selectableRowsHeader: false,
    filterType: 'textField' as const,
    draggableColumns: { enabled: true },
  }

  if (users) {
    tableData = users?.map((user) => {
      return {
        id: user.id,
        firstName: user.firstName || '---',
        lastName: user.lastName || '---',
        roles: user.roles ? user.roles.join(', ') : '---',
      }
    })
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    { label: 'Prénom', name: 'firstName' },
    { label: 'Nom', name: 'lastName' },
    {
      label: 'Rôles',
      name: 'roles',
      options: {
        filterType: 'dropdown' as const,
      },
    },
    {
      label: 'Actions',
      name: 'actions',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const user = users[dataIndex]
          const userId = user.id
          return (
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={onViewUserClick(userId)}
                color="secondary"
                size="small"
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setDeleteUserId(userId)
                }}
                style={{ marginLeft: 4 }}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )
        },
      },
    },
  ]
  return (
    <Box padding={2}>
      {getUsersQueryLoading ? (
        'Chargement'
      ) : (
        <>
          <Box padding={2}>
            <MUIDataTable
              {...{
                title: (
                  <Typography variant="h4" component="h1">
                    Tous les utilisateurs
                  </Typography>
                ),
                columns,
                data: tableData,
                options,
              }}
            />
          </Box>

          <Fab
            color="primary"
            aria-label="add"
            className={classes.addFab}
            onClick={() => setAddUserDialogOpen(true)}
          >
            <AddIcon />
          </Fab>
        </>
      )}

      {addUserDialogOpen && (
        <AddUser open onClose={() => setAddUserDialogOpen(false)} />
      )}

      {deleteUserId && (
        <DeleteUserDialog
          userId={deleteUserId}
          open
          onClose={() => setDeleteUserId('')}
        />
      )}
    </Box>
  )
}

export default UsersList
