import { useMutation, useQuery } from '@apollo/react-hooks'
import { QueryResult } from '@apollo/react-common'
import { GET_ME } from './query'
import { CHECK_TOKEN, RESET_PASSWORD } from './mutation'
import { GetMe, GetMe_me } from './types/GetMe'
import React from 'react'
import { ResetPassword, ResetPasswordVariables } from './types/ResetPassword'
import { CheckToken, CheckTokenVariables } from './types/CheckToken'
import { ApolloError } from 'apollo-client'

export const useGetMe: () => [
  GetMe_me | undefined,
  Pick<QueryResult<GetMe>, 'loading' | 'error'>,
] = () => {
  const { data, loading, error } = useQuery<GetMe>(GET_ME)
  return [data?.me, { loading, error }]
}

export const usePasswordRecover: (
  onError: (error: ApolloError) => void,
) => [
  (input: ResetPasswordVariables) => void,
  { loading: boolean; error: string },
] = (onError) => {
  const [error, setError] = React.useState<string>('')
  const [doPasswordRecover, { loading }] = useMutation<
    ResetPassword,
    ResetPasswordVariables
  >(RESET_PASSWORD)

  const mutate = (input: ResetPasswordVariables) => {
    setError('')
    doPasswordRecover({ variables: { ...input } }).catch((error) =>
      onError(error),
    )
  }

  return [mutate, { loading, error }]
}

export const useTokenCheck: (
  onError: (error: ApolloError) => void,
) => [
  (input: CheckTokenVariables) => void,
  { loading: boolean; error: string },
] = (onError) => {
  const [error, setError] = React.useState<string>('')
  const [doTokenChecke, { loading }] = useMutation<
    CheckToken,
    CheckTokenVariables
  >(CHECK_TOKEN)

  const mutate = (input: CheckTokenVariables) => {
    setError('')
    doTokenChecke({ variables: { ...input } }).catch((error) => onError(error))
  }

  return [mutate, { loading, error }]
}
