import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    // backgroundImage: "url('/images/background.png')",
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    padding: theme.spacing(0, 8),
    paddingBottom: theme.spacing(6),
    flexBasis: 640,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formBox: {
    border: '8px solid #ffffff',
    borderRadius: theme.spacing(8),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(5),
    backgroundColor: '#E48657',
  },
  titleBox: {
    border: '8px solid #ffffff',
    borderRadius: theme.spacing(8),
    padding: theme.spacing(1),
    backgroundColor: '#E48657',
    bottom: -theme.spacing(6),
    width: 320,
    alignSelf: 'center',
    position: 'relative',
    margin: 'auto',
  },
  title: {
    fontFamily: 'GillSansUltraBold',
    color: 'white',
    textAlign: 'center',
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  suggestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
    '& .MuiOutlinedInput-input': {
      padding: 8,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center',
    },
    '& .MuiInputBase-input': {
      color: 'white!important',
      fontFamily: 'GillSansUltraBold',
      fontSize: '1.5rem',
    },
    '& input[type="password"]': {
      // fontFamily: 'fontello',
      // fontWeight: 'bold',
      speak: 'none',

      /* For safety - reset parent styles, that can break glyph codes*/
      fontVariant: 'normal',
      // textTransform: 'none',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
    '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active ': {
      WebkitTextFillColor: 'white',
      WebkitBackgroundClip: 'text',
    },
  },
  signInButton: {
    margin: 0,
    height: 54,
    marginTop: theme.spacing(2),
    '& .MuiButton-label': {
      textTransform: 'none',
      height: 48,
    },
  },
  unityElement: {
    fontFamily: 'GillSansUltraBold',
    borderRadius: theme.spacing(4),
    border: '4px solid #ffffff',
    fontSize: '1.5rem',
    padding: 0,
  },
  unityLabel: {
    color: 'white',
    backgroundColor: '#E48657',
    padding: theme.spacing(1),
    border: '2px solid #ffffff',
    borderRadius: 16,
  },
  noBorder: {
    border: 'none',
  },
}))

export default useStyles
