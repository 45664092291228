import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #555555',
    flex: 1,
  },
}))

const Content: React.FC = (props) => {
  const classes = useStyles()
  return <div className={classes.root}>{props.children}</div>
}

export default Content
