import { Query } from './Query'
import { Mutation } from './Mutation'
// import TypeResolvers from './Type';

const resolvers = {
  Query,
  Mutation,
  // ...TypeResolvers,
}

export default resolvers
