import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import { useUpdateUser } from '../../../../../domains/user/useUser'

import CustomFormBuilder, {
  FormSection,
} from '../../../../../components/CustomFormBuilder'
import { GetUser_user } from 'domains/user/types/GetUser'
// import { useUpdateMyAvatar } from 'domains/avatar/useAvatar'

type UserProps = {
  user: GetUser_user | null | undefined
}

const User: React.FC<UserProps> = (props) => {
  const { user } = props

  const [modeDisplay, setModeDisplay] = React.useState(true)

  const [doUpdateUser, { loading: doUpdateUserLoading }] = useUpdateUser(
    user?.id || '',
    {
      onCompleted: () => setModeDisplay(true),
    },
  )

  // const [
  //   doUpdateMyAvatar,
  //   { loading: doUpdateMyAvatarLoading },
  // ] = useUpdateMyAvatar()

  const onFormSubmit = (data: any) => {
    const { avatarID, ...userValues } = data
    doUpdateUser(userValues)

    // if (avatarID) doUpdateMyAvatar(avatarID)
  }

  const formContent: FormSection[] = [
    {
      label: 'Prénom',
      name: 'firstName',
      defaultValue: user?.firstName,
      component: 'TextField',
      readOnly: modeDisplay,
      wrapProps: { xs: 6 },
    },
    {
      label: 'Nom',
      name: 'lastName',
      defaultValue: user?.lastName,
      component: 'TextField',
      readOnly: modeDisplay,
      wrapProps: { xs: 6 },
    },
    {
      label: "Nom d'utilisateur",
      name: 'username',
      defaultValue: user?.username,
      component: 'TextField',
      readOnly: modeDisplay,
      wrapProps: { xs: 6 },
    },
    {
      label: 'Email',
      name: 'email',
      defaultValue: user?.email,
      component: 'TextField',
      readOnly: modeDisplay,
      wrapProps: { xs: 6 },
    },
    // {
    //   label: "Années d'expériences",
    //   name: 'experienceYears',
    //   defaultValue: user?.experienceYears,
    //   component: 'TextField',
    //   readOnly: modeDisplay,
    //   wrapProps: { xs: 6 },
    // },
    // {
    //   label: 'Compétences',
    //   name: 'skills',
    //   defaultValue: user?.skills,
    //   component: 'TextField',
    //   readOnly: modeDisplay,
    // },
    // {
    //   label: 'Objectif à court terme',
    //   name: 'objectifCourtTerme',
    //   defaultValue: user?.objectifCourtTerme,
    //   component: 'TextField',
    //   readOnly: modeDisplay,
    // },
    // {
    //   label: 'Objectif à lont terme',
    //   name: 'objectifCourtTerme',
    //   defaultValue: user?.objectifLongTerme,
    //   component: 'TextField',
    //   readOnly: modeDisplay,
    // },
    // {
    //   label: 'Rôle',
    //   name: 'role',
    //   defaultValue: user?.role,
    //   component: 'Select',
    //   options: ['ADMIN', 'TALENT'],
    //   readOnly: modeDisplay,
    //   wrapProps: { xs: 6 },
    // },
  ]

  return (
    <div>
      <CustomFormBuilder
        {...{
          formContent,
          onFormCancel: () => setModeDisplay(true),
          onFormSubmit,
          noResetBtn: modeDisplay,
          noSubmitBtn: modeDisplay,
          // submitLoading: doUpdateUserLoading || doUpdateMyAvatarLoading,
          submitLoading: doUpdateUserLoading,
        }}
      />
      {modeDisplay && (
        <Box marginTop={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setModeDisplay(false)}
          >
            Modifier
          </Button>
        </Box>
      )}
    </div>
  )
}

export default User
