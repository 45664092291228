import { useMutation } from '@apollo/react-hooks'
import { AddActionLog } from './types/AddActionLog'
import { ADD_ACTION_LOG } from './mutation'
import { AddActionLogInput } from 'types/globalTypes'

export const useAddActionLog = () => {
  const [doAddActionLog, result] = useMutation<AddActionLog>(ADD_ACTION_LOG)

  const mutate = (input: AddActionLogInput) => {
    doAddActionLog({ variables: { input } }).catch((error) => {
      console.log('Variables:', input)
      const { graphQLErrors } = error
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const message = graphQLErrors[0].message
        console.error(message)
      }
    })
  }

  return { mutate, result }
}
