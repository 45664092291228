import React from 'react'
import { Box, Grid, useTheme } from '@material-ui/core'
import { toPng } from 'html-to-image'
import { ImgComparisonSlider } from '@img-comparison-slider/react'
import useStyles from '../../ChapterTwo/HtmlCss/HtmlCss.styles'
import Header from './components/Section/Header'
import Content from './components/Section/Content'
import { Editor } from '../../../components/Editor'
import Button from '@material-ui/core/Button'
import { useStopwatch } from 'react-timer-hook'
import Typography from '@material-ui/core/Typography'
import { useMutation } from '@apollo/react-hooks'
import { COMPARE_IMAGES } from '../../../domains/image/mutation'
import {
  CompareImages,
  CompareImagesVariables,
} from '../../../domains/image/types/CompareImages'
import { imageToBase64 } from '../../../helpers/imageToBase64'
import {
  MinimizedUnityContext,
  useUnityMessaging,
} from '../../UnityHtml/UnityHtml'
import {
  ChapterNumber,
  getImageToProcess,
  RandomImage,
} from '../../../helpers/getImageToProcess'

const initData =
  '<div id="div"></div>\n' +
  '<style>\n' +
  '  #div {\n' +
  '     width:100px;\n' +
  '     height:100px;\n' +
  '     background-color: #9b6aeb;\n' +
  '  }\n' +
  '</style>\n'

type HtmlCssProps = {
  chapter: ChapterNumber
}

const HtmlCss: React.FC<HtmlCssProps> = (props) => {
  const { sendMessage } = useUnityMessaging()

  const { chapter } = props
  const theme = useTheme()
  const [originalImage, setOriginalImage] = React.useState<RandomImage | null>(
    null,
  )
  const classes = useStyles()
  const { setIsUnityMinimized } = React.useContext(MinimizedUnityContext)
  const { seconds, minutes, hours, days, start, pause, reset } = useStopwatch({
    autoStart: false,
  })
  const [compareImages] = useMutation<CompareImages, CompareImagesVariables>(
    COMPARE_IMAGES,
    {
      onCompleted: (data) => {
        const timestamp = ((days * 24 + hours) * 60 + minutes) * 60 + seconds
        console.log(timestamp)
        sendMessage(
          'ReactLink',
          'FinishCurrentHTMLQuest',
          JSON.stringify({
            responseTime: timestamp,
            percentage: data.compareImages.percentage,
          }),
        )
        setIsUnityMinimized(false)
        reset()
      },
    },
  )
  const resultRef = React.useRef<HTMLImageElement | null>(null)
  const [codeImage, setCodeImage] = React.useState('')
  const [code, setCode] = React.useState(initData)
  const onCodeChange = (editorCode: string) => {
    setCode(editorCode)
  }
  const renderCode = React.useCallback(() => {
    try {
      const div = document.createElement('div')
      div.style.width = '400px'
      div.style.height = '300px'
      div.innerHTML = code
      toPng(div, { width: 400, height: 300 })
        .then(function (dataUrl) {
          if (resultRef.current) {
            setCodeImage(dataUrl)
            resultRef.current.src = dataUrl
          }
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error)
        })
    } catch (e) {
      console.log(e)
    }
  }, [code])

  const backToUnity = () => {
    sendMessage('ReactLink', 'Quit')
    setIsUnityMinimized(false)
    reset()
  }
  const processImageComparison = async () => {
    pause()
    const img = new Image()
    img.src = originalImage?.image
    const target = await imageToBase64(img.src)
    await compareImages({
      variables: { output: codeImage, target },
    })
  }

  React.useEffect(() => {
    const image = getImageToProcess(chapter)
    setOriginalImage(image)
  }, [chapter])

  React.useEffect(() => {
    renderCode()
    start()
  }, [originalImage])
  React.useEffect(() => {
    renderCode()
  }, [code])

  const copyToClipboard = async (color: string) => {
    try {
      await navigator.clipboard.writeText(color)
    } catch (e) {
      console.log('Unable to copy to clipboard')
    }
  }

  return (
    <Grid container className={classes.root} direction="row" wrap={'nowrap'}>
      <Grid item className={classes.editor} container direction="column">
        <Header
          title={'EDITEUR'}
          right={
            <Typography variant="h5" align="left">
              {days > 0 && `${handleTen(days)}:`}
              {hours > 0 && `${handleTen(hours)}:`}
              {minutes > 0 && `${handleTen(minutes)}:`}
              {handleTen(seconds)}
            </Typography>
          }
        />
        <Content>
          <Editor
            lang="html"
            onChange={onCodeChange}
            initialData={initData}
            height="100%"
          />
        </Content>
      </Grid>
      <Grid item className={classes.fixCol} container direction="column">
        <Header title={'RENDU'} />
        <Content>
          <Box className={classes.fixColBox}>
            <ImgComparisonSlider hover={true}>
              <img
                slot="second"
                width={400}
                height={300}
                src={originalImage?.image}
                alt={''}
              />
              <img
                slot="first"
                ref={resultRef}
                alt={''}
                width={400}
                height={300}
              />
            </ImgComparisonSlider>
          </Box>
          <Box
            marginBottom={2}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <Typography
              align="center"
              style={{ color: 'white', paddingTop: '8px' }}
            >
              <Typography
                component="span"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                Couleur à utiliser
              </Typography>
              &nbsp; ( cliquer pour copier )
            </Typography>
            <Box justifyContent="space-between" display="flex" padding={2}>
              {originalImage?.colors?.map((color) => (
                <Box
                  height={32}
                  style={{ backgroundColor: color }}
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    variant="text"
                    onClick={() => copyToClipboard(color)}
                  >
                    <Typography style={{ color: 'black' }}>{color}</Typography>
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
          <Box justifyContent="space-around" display="flex">
            <Button
              variant="contained"
              color="primary"
              onClick={processImageComparison}
            >
              Valider
            </Button>
            <Button variant="contained" color="primary" onClick={backToUnity}>
              Quitter
            </Button>
          </Box>
        </Content>
      </Grid>
      <Grid item className={classes.fixCol} container direction="column">
        <Header title={'OBJECTIF'} />
        <Content>
          <Box className={classes.fixColBox}>
            <img
              width={400}
              height={300}
              src={originalImage?.image}
              alt={''}
            />
          </Box>
        </Content>
      </Grid>
    </Grid>
  )
}

const handleTen = (value: number) => {
  if (value > 0) {
    return value > 9 ? value : `0${value}`
  } else return '00'
}

export default HtmlCss
