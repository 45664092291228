import scenario11 from '../assets/images/html-css/scenario1-1.png'
import scenario21 from '../assets/images/html-css/scenario2-1.png'
import scenario22 from '../assets/images/html-css/scenario2-2.png'
import scenario23 from '../assets/images/html-css/scenario2-3.png'
import scenario24 from '../assets/images/html-css/scenario2-4.png'
import scenario31 from '../assets/images/html-css/scenario3-1.png'
import scenario32 from '../assets/images/html-css/scenario3-2.png'
import scenario33 from '../assets/images/html-css/scenario3-3.png'
import scenario34 from '../assets/images/html-css/scenario3-4.png'
import scenario41 from '../assets/images/html-css/scenario4-1.png'
import scenario42 from '../assets/images/html-css/scenario4-2.png'
import scenario43 from '../assets/images/html-css/scenario4-3.png'
import scenario44 from '../assets/images/html-css/scenario4-4.png'
import scenario12 from '../assets/images/html-css/scenario1-2.png'
import scenario13 from '../assets/images/html-css/scenario1-3.png'
import scenario14 from '../assets/images/html-css/scenario1-4.png'

export type ChapterNumber = 1 | 2 | 3 | 4

export type RandomImage = { image: any; colors: Array<string> }

const images = [
  [scenario11, scenario13, scenario31, scenario14],
  [scenario21, scenario22, scenario23, scenario24],
  [scenario12, scenario32, scenario33, scenario34],
  [scenario41, scenario42, scenario43, scenario44],
]

const colors = [
  [
    ['#62374E', '#FDC57B'],
    ['#6592CF', '#243D83', '#EEB850'],
    ['#62306D', '#F7EC7D'],
    ['#0B2429', '#998235', '#F3AC3C'],
  ],
  [
    ['#E3516E', '#51B5A9', '#FADE8B', '#F7F3D7'],
    ['#0B2429', '#1A4341', '#998235', '#F3AC3C'],
    ['#6592CF', '#060F55'],
    ['#222730', '#4CAAB3', '#393E46'],
  ],
  [
    ['#62306D', '#F7EC7D', '#AA445F', '#E38F66'],
    ['#191210', '#ECA03D', '#84271C'],
    ['#D25B70', '#F6DF96', '#6CB3A9'],
    ['#6CB3A9', '#D25B70', '#781728', '#F6DF96', '#FFFFFF'],
  ],
  [
    ['#F2F2B6', '#FF6D00', '#FD4602'],
    ['#09042A', '#7B3F61', '#E78481'],
    ['#0B2429', '#998235', '#F3AC3C'],
    ['#09042A', '#F5BB9C', '#E78481'],
  ],
]
export const getImageToProcess: (scenario: ChapterNumber) => RandomImage = (
  scenario,
) => {
  switch (scenario) {
    case 1:
      return getRandomImage(1)
    case 2:
      return getRandomImage(2)
    case 3:
      return getRandomImage(3)
    case 4:
      return getRandomImage(4)
  }
}

const getRandomImage: (chapterNumber: ChapterNumber) => RandomImage = (
  chapterNumber,
) => {
  const randomIndex = getRandomArbitrary(4, 0)
  return {
    image: images[chapterNumber - 1][randomIndex - 1],
    colors: colors[chapterNumber - 1][randomIndex - 1],
  }
}

export const getRandomArbitrary = (max: number, min: number) => {
  return Math.floor(Math.random() * (max - min) + 1)
}
