import React from 'react';
import { useFieldArray } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { InputControl } from '..';
import ControlRenderer from '../ControlRenderer';

interface ArrayFieldProps {
  input: InputControl;
  control: any;
  error: any;
  getValues: (payload?: string | string[]) => Object;
  setValue: (arg0: string | { [name: string]: any }, value?: any, config?: Object) => void;
  register: any;
}

const UncontrolledArrayField: React.FC<ArrayFieldProps> = (props) => {
  const { input, control, error, getValues, setValue, register } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: input.name,
  });

  return (
    <React.Fragment>
      {fields.map((item, index) => (
        <Grid key={`${item.id}}`} item xs={12} container wrap="nowrap">
          <FormControl error={!!error && !!error[index]} fullWidth>
            <ControlRenderer
              {...{
                input: {
                  ...input,
                  name: `${input.name}[${index}].value`,
                  defaultValue: item.value,
                },
                register,
                getValues,
                setValue,
                control,
                error: !!error && !!error[index],
              }}
            />

            {!!error && !!error[index]?.value?.message && (
              <FormHelperText>{error[index].value.message}</FormHelperText>
            )}
          </FormControl>

          <Box marginLeft={1}>
            <IconButton
              disabled={input.readOnly}
              aria-label="delete"
              onClick={(e) => remove(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>
      ))}

      <Box marginLeft={1}>
        <IconButton
          disabled={input.readOnly}
          aria-label="add"
          onClick={(e) => append({ value: '' })}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
    </React.Fragment>
  );
};

export default UncontrolledArrayField;
