export const imageToBase64 = async (urlImg: any) => {
  const img = new Image()
  img.src = urlImg
  img.crossOrigin = 'Anonymous'
  const canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d')

  canvas.height = img.naturalHeight
  canvas.width = img.naturalWidth
  if (ctx) ctx.drawImage(img, 0, 0)

  return canvas.toDataURL('image/png').replace(/^data:image.+;base64,/, '')
}
