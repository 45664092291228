import makeStyles from '@material-ui/core/styles/makeStyles'

const pythonStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#0f1117',
    height: 'calc( 100vh - 64px )',
    overflowX: 'scroll',
    flex: 1,
  },
  fixCol: {
    minWidth: '440px',
    maxWidth: '440px',
    width: '440px',
  },
  fixColBox: {
    alignContent: 'center',
    padding: '16px',
  },
  editor: {
    minWidth: '440px',
    flex: 1,
  },
}))

export default pythonStyles
