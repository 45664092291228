import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid, { GridProps } from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';

import { CustomFormControl, InputControl } from '.';
import CustomButton from '../CustomButton';

const styles = (theme: Theme) =>
  createStyles({
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    formControl: {
      width: '100%',
      '& .MuiTextField-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    actionButtonsWrap: {
      marginTop: theme.spacing(2),
      '&>*+*': {
        marginLeft: theme.spacing(1),
      },
    },
    sectionContent: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
    },
  });

export interface CustomInput extends InputControl {
  wrapProps?: GridProps;
}

export function isCustomInput(object: any): object is CustomInput {
  return 'name' in object && 'label' in object;
}

export type FormSection =
  | { title: string | React.ReactNode; content: CustomInput[]; wrapProps?: GridProps }
  | CustomInput;

export interface CustomFormSectionsProps extends WithStyles<typeof styles> {
  formContent: FormSection[];
  onCancel?: Function;
  resetButtonText?: string;
  submitButtonText?: string;
  noResetBtn?: boolean;
  noSubmitBtn?: boolean;
  submitLoading?: boolean;
  useCustomFormMethods: {
    handleSubmit?: ((event: React.FormEvent<HTMLFormElement>) => void) | undefined;
    control?: any;
    getValues: (payload?: string | string[]) => any;
    setValue: (arg0: string | Object, value?: any, config?: Object) => void;
    errors: any;
    reset: any;
    register: any;
    isControlledMode: boolean;
  };
}

const CustomFormSections: React.FC<CustomFormSectionsProps> = (props) => {
  const {
    classes,
    formContent,
    onCancel,
    resetButtonText,
    submitButtonText,
    noResetBtn,
    noSubmitBtn,
    submitLoading,
    useCustomFormMethods: {
      handleSubmit,
      register,
      control,
      errors,
      getValues,
      setValue,
      reset,
      isControlledMode,
    },
  } = props;

  return (
    <Box component={handleSubmit ? 'form' : 'div'} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {formContent.map((item, i) => (
          <Grid key={`form-item-${i}`} item {...{ xs: 12, ...item.wrapProps }}>
            {isCustomInput(item) ? (
              <CustomFormControl
                {...{
                  register,
                  input: item,
                  getValues,
                  setValue,
                  control,
                  error: errors[item.name],
                  classes: { formControl: classes.formControl },
                  isControlledMode,
                }}
              />
            ) : (
              <div>
                {typeof item.title === 'string' ? (
                  <Typography component="p" variant="h5" gutterBottom>
                    {item.title}
                  </Typography>
                ) : (
                  item.title
                )}

                <Box padding={3 / 2}>
                  <Grid container spacing={3} className={classes.sectionContent}>
                    {item.content.map((input, j) => (
                      <Grid key={`form-input-${j}`} item {...{ xs: 4, ...input.wrapProps }}>
                        <CustomFormControl
                          {...{
                            register,
                            input,
                            getValues,
                            setValue,
                            control,
                            error: errors[input.name],
                            classes: { formControl: classes.formControl },
                            isControlledMode,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </div>
            )}
          </Grid>
        ))}
      </Grid>

      {(!noResetBtn || !noSubmitBtn) && (
        <div className={classes.actionButtonsWrap}>
          {!noResetBtn && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                reset();
                if (onCancel) onCancel();
              }}
            >
              {resetButtonText || 'Annuler'}
            </Button>
          )}

          {handleSubmit && !noSubmitBtn && (
            <CustomButton loading={submitLoading} type="submit" variant="contained" color="primary">
              {submitButtonText || 'Sauvegarder'}
            </CustomButton>
          )}
        </div>
      )}
    </Box>
  );
};

export default withStyles(styles)(CustomFormSections);
