import React, { memo, useContext, useRef, useCallback } from 'react'
import { Unity, useUnityContext } from 'react-unity-webgl'
import Box from '@material-ui/core/Box'
import { Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import AppContext from 'AppContext'

import {
  useGetIsLoggedIn,
  useGetMe,
  useLogout,
} from '../../domains/user/useUser'
import { useAddActionLog } from 'domains/actionLogs/useActionLogs'
import { useGetRanking } from '../../domains/leaderboard/useLeaderboard'
import useStyles from './UnityPython.styles'
import initPlayerValue from './EventHandler/initPlayerValue'
import {
  useUpdateChapterStatusData,
  useUpdateQuestStatusData,
  useUpdateGameData,
  useUpdateCardData,
  useUpdateCodeData,
} from '../../domains/gameData/gameData'
import HtmlCss from '../ChapterTwo/HtmlCss/HtmlCss'
import Js from '../ChapterTwo/JS/Js'
import Python from '../ChapterTwo/Python/Python'
import PythonMultiplayer from '../ChapterTwo/Python/PythonMultiplayer'
import { ChapterNumber } from '../../helpers/getImageToProcess'
import { Helmet } from 'react-helmet'
import { UnityAssetsPath } from '../../config'
import { useBugReport } from 'domains/bugReport/useBugReport'
// import getRanking from './EventHandler/getRanking'
import TextSlideshow from 'components/TextSlideshow'
import ImageSlideshow from 'components/ImageSlideshow'
import { useHistory } from 'react-router-dom'
import retrieveSceneTarget from './EventHandler/retrieveSceneTarget'
import getRanking from 'views/Unity/EventHandler/getRanking'

// export const {
//   unityProvider,
//   addEventListener,
//   removeEventListener,
//   unload,
//   sendMessage,
//   isLoaded,
//   loadingProgression,
// } = useUnityContext({
//   loaderUrl: UnityAssetsPath + 'UnityBuild/BUILD_NEW.loader.js',
//   dataUrl: UnityAssetsPath + 'UnityBuild/BUILD_NEW.data',
//   frameworkUrl: UnityAssetsPath + 'UnityBuild/BUILD_NEW.framework.js',
//   codeUrl: UnityAssetsPath + 'UnityBuild/BUILD_NEW.wasm',
// })

export interface UnityMessagingData {
  unityProvider: any // Adjust the type as per your actual implementation
  sendMessage: (
    messageType: string,
    methodName: string,
    message: string,
  ) => void
  // Add other Unity-related data and functions as needed
}

export function useUnityMessaging() {
  const {
    unityProvider,
    addEventListener,
    removeEventListener,
    unload,
    sendMessage,
    isLoaded,
    loadingProgression,
  } = useUnityContext({
    loaderUrl: UnityAssetsPath + 'PythonBuild/PYTHON.loader.js',
    dataUrl: UnityAssetsPath + 'PythonBuild/PYTHON.data.unityweb',
    frameworkUrl: UnityAssetsPath + 'PythonBuild/PYTHON.framework.js.unityweb',
    codeUrl: UnityAssetsPath + 'PythonBuild/PYTHON.wasm.unityweb',
  })

  return {
    unityProvider,
    addEventListener,
    removeEventListener,
    unload,
    sendMessage,
    isLoaded,
    loadingProgression,
  }
}

export const MinimizedUnityContext = React.createContext<{
  isUnityMinimized: boolean
  setIsUnityMinimized: any
}>({
  isUnityMinimized: false,
  setIsUnityMinimized: () => {},
})

export const ShownEditorContext = React.createContext<{
  jsEditorShown: boolean
  setJsEditorShown: any
  pythonEditorShown: boolean
  setPythonEditorShown: any
  pythonMultiplayerEditorShown: boolean
  setPythonMultiplayerEditorShown: any
  codeValidation: boolean
  setCodeValidation: any
  editorCode: string
  setEditorCode: any
  sendMessage: any
}>({
  jsEditorShown: false,
  setJsEditorShown: () => {},
  pythonEditorShown: false,
  setPythonEditorShown: () => {},
  pythonMultiplayerEditorShown: false,
  setPythonMultiplayerEditorShown: () => {},
  codeValidation: false,
  setCodeValidation: () => {},
  editorCode: '',
  setEditorCode: () => {},
  sendMessage: () => {},
})

const test = '/videos/login-signup.mp4'
const Playground: React.FC = () => {
  const classes = useStyles()
  const [progression, setProgression] = React.useState(0)
  const [isUnityMinimized, setIsUnityMinimized] = React.useState(false)
  const [jsEditorShown, setJsEditorShown] = React.useState(false)
  const [pythonEditorShown, setPythonEditorShown] = React.useState(false)
  const [pythonMultiplayerEditorShown, setPythonMultiplayerEditorShown] =
    React.useState(false)
  const [codeValidation, setCodeValidation] = React.useState(false)
  const [chapter, setChapter] = React.useState<ChapterNumber>(1)
  const [unityWidth, setUnityWidth] = React.useState('100%')
  const [lockData, setLockData] = React.useState(['', ''])
  const [generateSpawnPoint, setGenerateSpawnPointValue] =
    React.useState(false)
  const [passwordDigits, setPasswordDigits] = React.useState('')
  const [ticTacToeMatrix, setTicTacToeMatrix] = React.useState(
    new Array<number>(),
  )
  const [remainingAmmo, setRemainingAmmo] = React.useState(0)
  const isLoggedIn = useGetIsLoggedIn()
  const { mutate: doGameDataUpdate } = useUpdateGameData()
  const { mutate: doCardDataUpdate } = useUpdateCardData()
  const { mutate: doCodeDataUpdate } = useUpdateCodeData()
  const { mutate: doBugReport } = useBugReport()
  const { mutate: doChapterStatusDataUpdate } = useUpdateChapterStatusData()
  const { mutate: doQuestStatusDataUpdate } = useUpdateQuestStatusData()
  const { mutate: doAddActionLog } = useAddActionLog()
  const [me] = useGetMe()
  const [ranking] = useGetRanking()
  const { videoPlaying, setVideo } = useContext(AppContext)
  const { musicPlaying, setMusic } = useContext(AppContext)
  const [editorCode, setEditorCode] = React.useState('')

  const [imageProgression1, setImageProgression1] = React.useState(0)
  const [imageProgression2, setImageProgression2] = React.useState(0)
  const [imageProgression3, setImageProgression3] = React.useState(0)
  const [imageProgression4, setImageProgression4] = React.useState(0)
  const [imageProgression5, setImageProgression5] = React.useState(0)
  const history = useHistory()

  const DoLogout = () => {
    useLogout()
  }

  let lockDatas: string[] = []

  const onBugReport = useCallback((obj, description) => {
    console.log(obj + ': ' + description)
    doBugReport({
      obj: obj,
      description: description,
    })
  }, [])

  const onSavePlayerInfo = useCallback((data) => {
    const { name, avatarIndex } = JSON.parse(data)
    doGameDataUpdate({
      nickname: name,
      avatarIndex,
    })
  }, [])

  const onPocket = useCallback((type, amount) => {
    //if (type === 'gold')
    console.log(type + ': ' + amount)
    doGameDataUpdate({
      [type]: amount,
    })
  }, [])

  const onSetBoolValue = useCallback((identifier, value) => {
    console.log('set bool value')
    console.log(identifier + ' ' + Boolean(value))
    doGameDataUpdate({
      [identifier]: Boolean(value),
    })
  }, [])

  const onUpdateShowFirstTutorial = useCallback((showFirstTutorial) => {
    doGameDataUpdate({
      showFirstTutorial: Boolean(showFirstTutorial),
    })
  }, [])

  const onUpdateShowSecondTutorial = useCallback((showSecondTutorial) => {
    doGameDataUpdate({
      showSecondTutorial: Boolean(showSecondTutorial),
    })
  }, [])

  const onMusicSettings = useCallback((music, effect, volume) => {
    doGameDataUpdate({
      music: Boolean(music),
      sfx: Boolean(effect),
      volume: Number(volume.toFixed(2)),
    })
  }, [])

  const onActualLife = useCallback((maxLife, actualLife) => {
    doGameDataUpdate({ lives: actualLife })
  }, [])

  const onCurrentMaxExperience = useCallback((maxExperience) => {
    console.log('React side')
    doGameDataUpdate({ maxExperience })
  }, [])

  const onQuestStatus = useCallback(
    (
      languageIndex,
      questIndex,
      isComplete,
      showCompletion,
      correctAnswers,
      wrongAnswers,
      responseTime,
      score,
    ) => {
      doQuestStatusDataUpdate({
        languageIndex,
        questIndex,
        isComplete: Boolean(isComplete),
        showCompletion: Boolean(showCompletion),
        correctAnswers,
        wrongAnswers,
        responseTime,
        score,
      })
    },
    [],
  )

  const onChapterStatus = useCallback(
    (
      languageIndex,
      questIndex,
      chapterIndex,
      isUnlocked,
      isComplete,
      correctAnswers,
      wrongAnswers,
      responseTime,
      score,
    ) => {
      doChapterStatusDataUpdate({
        languageIndex,
        questIndex,
        chapterIndex,
        isUnlocked: Boolean(isUnlocked),
        isComplete: Boolean(isComplete),
        correctAnswers,
        wrongAnswers,
        responseTime,
        score,
      })
    },
    [],
  )

  const onNextRefillDate = useCallback((data) => {
    console.log('LL: App -> data', data)
    doGameDataUpdate({ refillDate: new Date(data) })
  }, [])

  const onCurrentScore = useCallback((score) => {
    console.log('LL: App -> data', score)
    console.log('React show', JSON.parse(score))
  }, [])

  const onRedirect = useCallback((sceneName) => {
    // unload()
    history.push(sceneName)
  }, [])

  const onSetTransitionTarget = useCallback((sceneName) => {
    console.log('setTransitionTarget: ' + sceneName)
    localStorage.setItem('sceneTarget', sceneName)
  }, [])

  const onHideUnity = useCallback((chapterIndex) => {
    setChapter(chapterIndex)
    setIsUnityMinimized(true)
  }, [])

  const onRegisterLockDatas = useCallback(
    (firstLock, secondLock, thirdLock) => {
      lockDatas.push(firstLock)
      lockDatas.push(secondLock)
      lockDatas.push(thirdLock)
      setLockData(lockDatas)
    },
    [],
  )

  const onSetGenerateSpawnPoints = useCallback((generate) => {
    setGenerateSpawnPointValue(generate)
  }, [])

  const onSetPasswordDigits = useCallback((digits) => {
    setPasswordDigits(digits)
  }, [])

  const onSetTicTacToeMatrix = useCallback((matrix) => {
    setTicTacToeMatrix(matrix)
  }, [])

  const onSetRemainingAmmo = useCallback((ammo) => {
    setRemainingAmmo(ammo)
  }, [])

  const onUpdateShowCardTutorial = useCallback((showCardTutorial) => {
    doGameDataUpdate({
      showCardTutorial: Boolean(showCardTutorial),
    })
  }, [])

  const onUpdateCode = useCallback((languageIndex, name, code) => {
    doCodeDataUpdate({
      languageIndex,
      name,
      code,
    })
  }, [])

  const onCardStatus = useCallback(
    (cardIndex, cardLife, cardAttack, cardMana) => {
      doCardDataUpdate({
        cardIndex,
        cardLife,
        cardAttack,
        cardMana,
      })
    },
    [],
  )

  const onShowEditor = useCallback((value, language) => {
    setJsEditorShown(value && language === 'javascript')
    setPythonEditorShown(value && language === 'python')
    setPythonMultiplayerEditorShown(value && language === 'pythonMultiplayer')
    setUnityWidth(value ? '70%' : '100%')
  }, [])

  const onInitializeEditor = useCallback((code) => {
    setEditorCode(code)
  }, [])

  const onEnableCodeValidation = useCallback(() => {
    setCodeValidation(true)
  }, [])

  const onDisableCodeValidation = useCallback(() => {
    setCodeValidation(false)
  }, [])

  const onLogout = useCallback(() => {
    DoLogout()
  }, [])

  const onAddActionLog = useCallback((actiondate, actiontype, actionvalue) => {
    doAddActionLog({
      actiondate,
      actiontype,
      actionvalue,
    })
  }, [])

  // const onGetRankingByLanguage = useCallback(
  //   (actiondate, actiontype, actionvalue) => {
  //     getRanking(ranking)
  //   },
  //   [],
  // )

  // const onGetRanking = useCallback((actiondate, actiontype, actionvalue) => {
  //   getRanking(ranking)
  // }, [])

  const {
    unityProvider,
    addEventListener,
    removeEventListener,
    sendMessage,
    isLoaded,
    loadingProgression,
  } = useUnityMessaging()

  const unityMessagingData: UnityMessagingData = {
    unityProvider,
    sendMessage,
    // Add other Unity-related data and functions obtained from useUnityMessaging
  }

  React.useEffect(() => {
    addEventListener('reportBug', onBugReport)
    addEventListener('savePlayerInfo', onSavePlayerInfo)
    addEventListener('pocket', onPocket)
    addEventListener('setBoolValue', onSetBoolValue)
    addEventListener('updateShowFirstTutorial', onUpdateShowFirstTutorial)
    addEventListener('updateShowSecondTutorial', onUpdateShowSecondTutorial)
    addEventListener('musicSettings', onMusicSettings)
    addEventListener('actualLife', onActualLife)
    addEventListener('currentMaxExperience', onCurrentMaxExperience)
    addEventListener('chapterStatus', onChapterStatus)
    addEventListener('questStatus', onQuestStatus)
    addEventListener('nextRefillDate', onNextRefillDate)
    addEventListener('currentScore', onCurrentScore)
    addEventListener('redirect', onRedirect)
    addEventListener('setTransitionTarget', onSetTransitionTarget)

    addEventListener('hideUnity', onHideUnity)
    addEventListener('registerLockDatas', onRegisterLockDatas)
    addEventListener('setGenerateSpawnPoints', onSetGenerateSpawnPoints)
    addEventListener('setPasswordDigits', onSetPasswordDigits)
    addEventListener('setTicTacToeMatrix', onSetTicTacToeMatrix)
    addEventListener('setRemainingAmmo', onSetRemainingAmmo)

    addEventListener('updateShowCardTutorial', onUpdateShowCardTutorial)
    addEventListener('updateCode', onUpdateCode)
    addEventListener('cardStatus', onCardStatus)
    addEventListener('showEditor', onShowEditor)
    addEventListener('initializeEditor', onInitializeEditor)
    addEventListener('enableCodeValidation', onEnableCodeValidation)
    addEventListener('disableCodeValidation', onDisableCodeValidation)
    addEventListener('logout', onLogout)
    addEventListener('addActionLog', onAddActionLog)
    // addEventListener('getRankingByLanguage', onGetRankingByLanguage)

    if (isLoaded) {
      setVideo(false)
      setMusic(false)
    }

    setProgression(loadingProgression * 100)
    setVideo(!isLoaded)
    setMusic(!isLoaded)

    // unityContext.on('loaded', function () {
    //   setVideo(false)
    //   setMusic(false)
    // })

    // unityContext.on('progress', function (progression: number) {
    //   setProgression(progression * 100)
    //   // setVideo(false)
    //   // setMusic(false)
    // })

    // unityContext.on('useCode', (code: string) => {})
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [addEventListener, removeEventListener, loadingProgression, isLoaded])

  React.useEffect(() => {
    // addEventListener('getRanking', onGetRanking)
    const getRankingHandler = () => {
      getRanking(unityMessagingData, ranking)
    }

    if (me !== undefined) {
      // If me is not undefined, add the event listener
      addEventListener('getRanking', getRankingHandler)
    }

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      removeEventListener('getRanking', getRankingHandler)
    }
  }, [unityMessagingData, ranking])

  React.useEffect(() => {
    const retrieveSceneTargetHandler = () => {
      retrieveSceneTarget(unityMessagingData)
    }

    if (me !== undefined) {
      // If me is not undefined, add the event listener
      addEventListener('retrieveTransitionTarget', retrieveSceneTargetHandler)
    }

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      removeEventListener(
        'retrieveTransitionTarget',
        retrieveSceneTargetHandler,
      )
    }
  }, [unityMessagingData, me])

  React.useEffect(() => {
    const reverseCallHandler = () => {
      console.log('REVERSE CALL')
      console.log(me)
      if (me) {
        initPlayerValue(unityMessagingData, me)
      } else {
        console.log('me is undefined') // Debugging statement
      }
    }

    if (me !== undefined) {
      // If me is not undefined, add the event listener
      addEventListener('reverseCall', reverseCallHandler)
    }

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      removeEventListener('reverseCall', reverseCallHandler)
    }
  }, [me, unityMessagingData])

  if (!isLoggedIn) return <Redirect to="/sign-in" />

  return (
    <MinimizedUnityContext.Provider
      value={{ isUnityMinimized, setIsUnityMinimized }}
    >
      {progression !== 100 && (
        <div className={classes.videoBackground}>
          <video muted autoPlay loop className={classes.video}>
            <source src={test} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <Helmet>
        <title>Genius 27</title>
      </Helmet>
      <ShownEditorContext.Provider
        value={{
          jsEditorShown,
          setJsEditorShown,
          pythonEditorShown,
          setPythonEditorShown,
          pythonMultiplayerEditorShown,
          setPythonMultiplayerEditorShown,
          codeValidation,
          setCodeValidation,
          editorCode,
          setEditorCode,
          sendMessage,
        }}
      >
        <Box height="100%">
          {progression !== 100 && (
            <Box
              className={classes.progressContainer}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {/* <Typography variant="h1" color="primary">
                CHARGEMENT...
              </Typography> */}
              <div className={classes.ImageSlider}>
                <ImageSlideshow
                  imageProgress1={setImageProgression1}
                  imageProgress2={setImageProgression2}
                  imageProgress3={setImageProgression3}
                  imageProgress4={setImageProgression4}
                  imageProgress5={setImageProgression5}
                />
              </div>
              <div className={classes.TextSlider}>
                <TextSlideshow />
              </div>
              <Box paddingY={3} width={'100%'}>
                <div className={classes.ImagesProgressContainer}>
                  <LinearProgress
                    variant="determinate"
                    className={classes.ImageProgress1}
                    value={imageProgression1}
                  />
                  <LinearProgress
                    variant="determinate"
                    className={classes.ImageProgress2}
                    value={imageProgression2}
                  />
                  <LinearProgress
                    variant="determinate"
                    className={classes.ImageProgress3}
                    value={imageProgression3}
                  />
                  <LinearProgress
                    variant="determinate"
                    className={classes.ImageProgress4}
                    value={imageProgression4}
                  />
                  <LinearProgress
                    variant="determinate"
                    className={classes.ImageProgress5}
                    value={imageProgression5}
                  />
                </div>
                <LinearProgress
                  variant="determinate"
                  className={classes.ProgressBar}
                  value={progression}
                  style={{ position: 'absolute', width: '100%' }}
                />
                {/* <CircularProgress
                  variant="determinate"
                  size={100}
                  value={progression}
                /> */}
              </Box>
            </Box>
          )}
          <Box
            style={{
              width: '100%',
              height: '100%',
              display: isUnityMinimized ? 'none' : 'block',
            }}
          >
            <Unity
              unityProvider={unityProvider}
              style={{
                height: isUnityMinimized ? 0 : '100%',
                width: isUnityMinimized ? 0 : unityWidth,
              }}
              tabIndex={1}
            />

            {jsEditorShown && (
              <Box
                style={{
                  height: '100%',
                  width: '30%',
                  float: 'right',
                }}
              >
                <Js
                  sendMessage={sendMessage}
                  codeValidation={codeValidation}
                  initialData={editorCode}
                />
              </Box>
            )}
            {pythonEditorShown && (
              <Box
                style={{
                  height: '100%',
                  width: '30%',
                  float: 'right',
                }}
              >
                <Python
                  sendMessage={sendMessage}
                  lockDatas={lockData}
                  generateSpawnPoints={generateSpawnPoint}
                  passwordDigits={passwordDigits}
                  ticTacToeMatrix={ticTacToeMatrix}
                  remainingAmmo={remainingAmmo}
                  codeValidation={codeValidation}
                />
              </Box>
            )}
            {pythonMultiplayerEditorShown && (
              <Box
                style={{
                  height: '100%',
                  width: '30%',
                  float: 'right',
                }}
              >
                <PythonMultiplayer sendMessage={sendMessage} />
              </Box>
            )}
          </Box>
          {isUnityMinimized && (
            <Box
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <HtmlCss chapter={chapter} />
            </Box>
          )}
        </Box>
      </ShownEditorContext.Provider>
    </MinimizedUnityContext.Provider>
  )
}

export default memo(Playground)
