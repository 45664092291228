import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TextFieldProps } from '@material-ui/core/TextField'

import { useAddUser } from '../../../../domains/user/useUser'
import CustomFormBuilder, {
  InputControl,
} from '../../../../components/CustomFormBuilder'

const useStyles = makeStyles((theme) => ({
  actionButtonsWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
}))

const formContent: InputControl[] = [
  {
    label: 'Prénom',
    name: 'firstName',
    component: 'TextField',
  },
  {
    label: 'Nom',
    name: 'lastName',
    component: 'TextField',
  },
  {
    label: "Nom d'utilisateur",
    name: 'username',
    component: 'TextField',
  },
  {
    label: 'Email',
    name: 'email',
    component: 'TextField',
  },
  // {
  //   label: 'Rôle',
  //   name: 'roles',
  //   component: 'Select',
  //   options: ['ADMIN', 'TALENT'],
  // },
  {
    label: 'Mot de passe',
    name: 'password',
    component: 'TextField',
    componentProps: { inputProps: { type: 'password' } } as TextFieldProps,
  },
]

const AddUser: React.FC<DialogProps> = (props) => {
  const classes = useStyles()
  const { onClose, ...other } = props

  const history = useHistory()
  const match = useRouteMatch()

  const [doAddUser, { loading: doAddUserLoading }] = useAddUser({
    onCompleted: ({ addUser }: any) =>
      history.push(`${match.path}${addUser.id}`),
  })

  const onFormSubmit = (data: any) => {
    doAddUser(data)
  }

  return (
    <Dialog
      {...{
        ...other,
        'aria-labelledby': 'add-user',
      }}
    >
      <DialogTitle id="add-user" disableTypography>
        <Typography component="h2" variant="h5">
          CRÉER UN UTILISATEUR
        </Typography>
      </DialogTitle>

      <DialogContent>
        <CustomFormBuilder
          {...{
            formContent,
            onFormCancel: onClose,
            onFormSubmit,
            submitLoading: doAddUserLoading,
            submitText: 'Créer',
            classes: { actionButtonsWrap: classes.actionButtonsWrap },
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default AddUser
