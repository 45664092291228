import gql from 'graphql-tag'
import fragment from '../user/fragments'

export const UPDATE_MY_GAME_DATA = gql`
  mutation UpdateMyGameData($input: UpdateMyGameDataInput!) {
    updateMyGameData(input: $input) {
      me {
        ...FragmentUserDetails
      }
    }
  }
  ${fragment.DETAILS}
`

export const UPDATE_MY_CHAPTERS_STATUS = gql`
  mutation UpdateMyChaptersStatus($input: UpdateMyChapterStatusInput!) {
    updateMyChapterStatus(input: $input) {
      chapterStatusItem {
        languageIndex
        questIndex
        chapterIndex
        isUnlocked
        isComplete
        correctAnswers
        wrongAnswers
        responseTime
        score
      }
    }
  }
`

export const UPDATE_MY_QUEST_STATUS = gql`
  mutation UpdateMyQuestStatus($input: UpdateMyQuestStatusInput!) {
    updateMyQuestStatus(input: $input) {
      questStatus {
        languageIndex
        questIndex
        isComplete
        showCompletion
        correctAnswers
        languageIndex
        wrongAnswers
        responseTime
        score
      }
    }
  }
`

export const UPDATE_MY_CARD_DATA = gql`
  mutation UpdateMyCardData($input: UpdateMyCardDataInput!) {
    updateMyCardData(input: $input) {
      cardData {
        cardIndex
        cardLife
        cardAttack
        cardMana
      }
    }
  }
`

export const UPDATE_MY_CODE_DATA = gql`
  mutation UpdateMyCodeData($input: UpdateMyCodeDataInput!) {
    updateMyCodeData(input: $input) {
      codeData {
        languageIndex
        name
        code
      }
    }
  }
`
