import React, { useRef } from 'react'
import { Grid } from '@material-ui/core'
import jsStyles from '../../ChapterTwo/JS/Js.styles'
import Header from './components/Section/Header'
import Content from './components/Section/Content'
import { Editor } from '../../../components/Editor'
import Button from '@material-ui/core/Button'
import { ReactUnityEventParameter } from 'react-unity-webgl/distribution/types/react-unity-event-parameters'

type JsProps = {
  sendMessage: (
    gameObjectName: string,
    methodName: string,
    parameter?: ReactUnityEventParameter,
  ) => void
  codeValidation: boolean
  initialData: string
}

const Js: React.FC<JsProps> = ({
  sendMessage,
  codeValidation,
  initialData,
}) => {
  const classes = jsStyles()
  const [userCode, setUserCode] = React.useState(initialData)

  const onCodeChange = (code: string) => {
    setUserCode(code)
  }

  const validate = () => {
    // if(!codeValidation)
    //   return
    sendMessage('Jint', 'InterpretInput', userCode)
  }

  async function evaluateCode() {
    console.log('Evaluate code')
    console.log(userCode)
    sendMessage('Violet Discussion Manager', 'EvaluateCode', userCode)
  }

  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      wrap={'nowrap'}
      tabIndex={2}
    >
      <Grid
        item
        md={6}
        className={classes.editor}
        container
        direction="column"
      >
        <Header title="EDITEUR" />
        <Content>
          <Editor
            lang="javascript"
            onChange={onCodeChange}
            initialData={initialData}
            height="90%"
          />
          <Button
            onClick={validate}
            style={{ position: 'relative', left: '20%', top: '10px' }}
          >
            Valider
          </Button>
          {/* <Button
            onClick={evaluateCode}
            style={{ position: 'relative', right: '20%', top: '10px' }}
          >
            Evaluer
          </Button> */}
        </Content>
      </Grid>
    </Grid>
  )
}

export default Js
