import React, { useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import { useForm } from 'react-hook-form'
import AppContext from 'AppContext'

import { CustomButton } from '../../components'
import { useGetIsLoggedIn, useLogin, useSso } from '../../domains/user/useUser'
import useStyles from './SignIn.styles'
import { Helmet } from 'react-helmet'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import InfoDialog from '../../components/InfoDialog'
import { PasswordResetEmailDialog } from '../../components/PasswordResetEmailDialog'
import VideoPlayer from 'components/VideoPlayer/VideoPlayer'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import { FB_ID } from 'utils/constants'
import LinearProgress from '@material-ui/core/LinearProgress'
import VideoSlideshowLogin from 'components/VideoSlideshowLogin/VideoSlideshowLogin'

const test = '/videos/login-signup.mp4'

const SignIn = () => {
  const classes = useStyles()
  const history = useHistory()
  const [openDialog, setOpenDialog] = React.useState(false)
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false)
  const [messageText, setMessageText] = React.useState('')
  const { register, errors, handleSubmit } = useForm()
  const isLoggedIn = useGetIsLoggedIn()
  const [showIntroductionVideo, setShowIntroductionVideo] =
    React.useState(false)
  const [showGameplayVideo, setShowGameplayVideo] = React.useState(false)
  const { videoPlaying, setVideo } = useContext(AppContext)
  const { musicPlaying, setMusic } = useContext(AppContext)
  const [isTouchScreen, setTouchScreenDevice] = React.useState(false)

  const [imageProgression1, setImageProgression1] = React.useState(0)
  const [imageProgression2, setImageProgression2] = React.useState(0)
  const [imageProgression3, setImageProgression3] = React.useState(0)
  const [imageProgression4, setImageProgression4] = React.useState(0)
  const [imageProgression5, setImageProgression5] = React.useState(0)
  const [imageProgression6, setImageProgression6] = React.useState(0)
  const [imageProgression7, setImageProgression7] = React.useState(0)

  const playHoverEffect = () => {
    const audio = new Audio('/audios/button-hover.wav')
    audio.volume = 0.1
    audio.play()
  }
  const playClickEffect = () => {
    const audio = new Audio('/audios/button-click.wav')
    audio.volume = 0.1
    audio.play()
  }
  const handleEmailDialogClick = () => {
    playClickEffect()
    showEmailDialog()
  }
  const handleSignupClick = (e) => {
    playClickEffect()
    goToSignupPage(e)
  }
  const handleShowIntroductionClick = (e) => {
    setShowIntroductionVideo(true)
    setMusic(false)
  }
  const handleHideIntroductionClick = (e) => {
    setShowIntroductionVideo(false)
    setMusic(true)
  }
  const handleShowGameplayClick = (e) => {
    setShowGameplayVideo(true)
    setMusic(false)
  }
  const handleHideGameplayClick = (e) => {
    setShowGameplayVideo(false)
    setMusic(true)
  }

  const { toggleMusic } = useContext(AppContext)
  const handleMusicClick = () => {
    toggleMusic()
  }

  const [doLogin, { loading: doLogInLoading, error: loginError }] = useLogin()
  const [doSsoLogin, { loading: doSsoLoading, error: ssoError }] = useSso()

  const onFormSubmit = (data) => {
    const { login, password } = data
    doLogin(login, password)
  }

  const googleLogin = useGoogleLogin({
    // onSuccess: tokenResponse => onGoogleLogin(tokenResponse),
    onSuccess: async (tokenResponse) => {
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data)

      doSsoLogin(userInfo.given_name, userInfo.family_name, userInfo.email)
    },
  })

  const onFacebookLogin = (credentialResponse) => {
    doSsoLogin(
      credentialResponse.first_name,
      credentialResponse.last_name,
      credentialResponse.email,
    )
  }

  const handleInfoClose = () => {
    setOpenDialog(false)
    setMessageText('')
  }

  const isTouchScreenDevice = () => {
    try {
      document.createEvent('TouchEvent')
      setTouchScreenDevice(true)
    } catch (e) {
      setTouchScreenDevice(false)
    }
  }

  React.useEffect(() => {
    isTouchScreenDevice()
  }, [])

  React.useEffect(() => {
    if (loginError) {
      setOpenDialog(true)
      switch (loginError) {
        case 'NETWORK_ERROR':
          setMessageText('Erreur de réseau,\nveuillez réessayer')
          break
        case 'user not found':
          setMessageText('Utilisateur non trouvé')
          break
        case 'wrong password':
          setMessageText('Mot de passe incorrect')
          break
        default:
          setMessageText('Un erreur est survenu,\nveuillez réessayer')
          break
      }
    }
  }, [loginError])

  if (isLoggedIn) return <Redirect to="/" />

  const goToSignupPage = (e) => {
    e.preventDefault()
    history.push('/sign-up')
  }

  const hideEmailDialog = () => {
    setOpenEmailDialog(false)
  }

  const showEmailDialog = () => {
    setOpenEmailDialog(true)
  }

  const width = '110%'
  const height = '100%'

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Se connecter</title>
      </Helmet>
      <Grid className={classes.container} container>
        <Grid container className={classes.content} item xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <Box
                className={classes.progressContainer}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {/* <Typography variant="h1" color="primary">
                CHARGEMENT...
              </Typography> */}
                <div className={classes.ImageSlider}>
                  <VideoSlideshowLogin
                    imageProgress1={setImageProgression1}
                    imageProgress2={setImageProgression2}
                    imageProgress3={setImageProgression3}
                    imageProgress4={setImageProgression4}
                    imageProgress5={setImageProgression5}
                    imageProgress6={setImageProgression6}
                    imageProgress7={setImageProgression7}
                  />
                </div>
                <Box paddingY={3} width={'100%'}>
                  <div className={classes.ImagesProgressContainer}>
                    <LinearProgress
                      variant="determinate"
                      className={classes.ImageProgress1}
                      value={imageProgression1}
                    />
                    <LinearProgress
                      variant="determinate"
                      className={classes.ImageProgress2}
                      value={imageProgression2}
                    />
                    <LinearProgress
                      variant="determinate"
                      className={classes.ImageProgress3}
                      value={imageProgression3}
                    />
                    <LinearProgress
                      variant="determinate"
                      className={classes.ImageProgress4}
                      value={imageProgression4}
                    />
                    <LinearProgress
                      variant="determinate"
                      className={classes.ImageProgress5}
                      value={imageProgression5}
                    />
                    <LinearProgress
                      variant="determinate"
                      className={classes.ImageProgress6}
                      value={imageProgression6}
                    />
                    <LinearProgress
                      variant="determinate"
                      className={classes.ImageProgress7}
                      value={imageProgression7}
                    />
                  </div>
                  {/* <CircularProgress
                  variant="determinate"
                  size={100}
                  value={progression}
                /> */}
                </Box>
              </Box>
              {showIntroductionVideo && (
                <div className={classes.youtubeContainer}>
                  <CustomButton
                    className={clsx(
                      classes.link,
                      classes.hideYoutubeContainer,
                    )}
                    color="primary"
                    size="medium"
                    variant="contained"
                    onClick={(e) => handleHideIntroductionClick(e)}
                    onMouseEnter={playHoverEffect}
                  >
                    X
                  </CustomButton>
                  <VideoPlayer videoId="sErIY9M78vc" />
                </div>
              )}
              {showGameplayVideo && (
                <div className={classes.youtubeContainer}>
                  <CustomButton
                    className={clsx(
                      classes.link,
                      classes.hideYoutubeContainer,
                    )}
                    color="primary"
                    size="medium"
                    variant="contained"
                    onClick={(e) => handleHideGameplayClick(e)}
                    onMouseEnter={playHoverEffect}
                  >
                    X
                  </CustomButton>
                  <VideoPlayer videoId="h5CZ_ey94LA" />
                </div>
              )}
              <button
                className={classes.toggleMusicButton}
                onClick={handleMusicClick}
              >
                {musicPlaying && (
                  <img
                    style={{ width: '25px' }}
                    src="/music_on.png"
                    alt="Désactiver musique"
                  />
                )}
                {!musicPlaying && (
                  <img
                    style={{ width: '25px' }}
                    src="/music_off.png"
                    alt="Activer musique"
                  />
                )}
              </button>
              <Box className={classes.formContainer}>
                <Box className={classes.titleBox}>
                  <div className={classes.logoImage}></div>
                </Box>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <Box className={classes.formBox}>
                    <Typography className={classes.title} variant="h2">
                      Connectez-vous
                    </Typography>
                    <InputLabel className={classes.label}>Email</InputLabel>
                    <TextField
                      className={clsx(classes.textField, classes.unityElement)}
                      fullWidth
                      // placeholder={"Nom d'utilisateur"}
                      name="login"
                      type="text"
                      variant="outlined"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      inputRef={register({ required: true })}
                      error={!!errors.login}
                    />
                    <InputLabel className={classes.label}>
                      Mot de passe
                    </InputLabel>
                    <TextField
                      className={clsx(classes.textField, classes.unityElement)}
                      fullWidth
                      // placeholder={'Mot de passe'}
                      name="password"
                      type="password"
                      variant="outlined"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      inputRef={register({ required: true })}
                      error={!!errors.password}
                    />
                    {isTouchScreen && (
                      <InputLabel className={classes.recommandation}>
                        Pour une meilleure expérience, <br />
                        il est recommandé de jouer sur ordinateur
                      </InputLabel>
                    )}
                    <CustomButton
                      className={clsx(
                        classes.signInButton,
                        // classes.unityElement,
                      )}
                      color="primary"
                      fullWidth
                      size="medium"
                      type="submit"
                      variant="contained"
                      loading={doLogInLoading}
                      onClick={playClickEffect}
                      onMouseEnter={playHoverEffect}
                    >
                      Se connecter
                    </CustomButton>
                    <CustomButton
                      className={clsx(classes.ssoButton)}
                      color="primary"
                      size="medium"
                      variant="contained"
                      onClick={(e) => googleLogin(e)}
                      onMouseEnter={playHoverEffect}
                    >
                      <img
                        style={{ width: '25px' }}
                        src="/search.png"
                        alt="Se connecter avec google"
                      />
                      &nbsp;Se connecter avec google
                    </CustomButton>
                    {/*<FacebookLogin
                      appId={FB_ID}
                      autoLoad
                      fields="first_name,last_name,email,picture"
                      callback={onFacebookLogin}
                      render={(renderProps) => (
                        // <button class={clsx(classes.link)} onClick={renderProps.onClick}>Connexion Facebook</button>
                        <CustomButton
                          className={clsx(classes.ssoButton)}
                          color="primary"
                          size="medium"
                          variant="contained"
                          onClick={renderProps.onClick}
                          onMouseEnter={playHoverEffect}
                        >
                          <img
                            style={{ width: '25px' }}
                            src="/facebook.png"
                            alt="Se connecter avec facebook"
                          />
                          &nbsp;Se connecter avec facebook
                        </CustomButton>
                      )}
                    />*/}
                    {/*<Box marginTop={2}>
                      <Typography className={classes.title} variant="h2">
                        ou
                      </Typography>
                    </Box>*/}
                  </Box>
                </form>
                <hr className={classes.horizontalRule} />
                <CustomButton
                  className={clsx(classes.link)}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={handleEmailDialogClick}
                  onMouseEnter={playHoverEffect}
                >
                  Mot de passe oublié
                </CustomButton>
                <CustomButton
                  className={clsx(classes.link)}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={(e) => handleSignupClick(e)}
                  onMouseEnter={playHoverEffect}
                >
                  Créer un compte
                </CustomButton>
                {/* <CustomButton
                  className={clsx(classes.link)}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={(e) => handleShowIntroductionClick(e)}
                  onMouseEnter={playHoverEffect}
                >
                  Introduction
                </CustomButton>
                <CustomButton
                  className={clsx(classes.link)}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={(e) => handleShowGameplayClick(e)}
                  onMouseEnter={playHoverEffect}
                >
                  Gameplay
                </CustomButton> */}
                {/* <div className={classes.genius}></div> */}
                <div className={classes.giris}></div>
              </Box>
            </div>
          </div>
        </Grid>
      </Grid>
      <InfoDialog
        onClose={handleInfoClose}
        title={'Erreur'}
        contentText={messageText}
        open={openDialog}
      />
      {openEmailDialog && (
        <PasswordResetEmailDialog open={true} onClose={hideEmailDialog} />
      )}
    </div>
  )
}

SignIn.propTypes = {
  history: PropTypes.object,
}

export default SignIn
