import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Drawer } from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import GamesIcon from '@material-ui/icons/Games'
// import FaceIcon from '@material-ui/icons/Face'

import { Profile, SidebarNav } from './components'
import { useGetMe } from 'domains/user/useUser'
import { Role } from 'types/globalTypes'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props

  const [me] = useGetMe()

  const classes = useStyles()

  const pages = React.useMemo(
    () => [
      ...(() =>
        me?.roles?.length && me.roles.includes(Role.ADMIN)
          ? [
              {
                title: 'Utilisateurs',
                href: '/users',
                icon: <PeopleIcon />,
              },
            ]
          : [])(),
      {
        title: 'Formations',
        href: '/formations',
        icon: <LibraryBooksIcon />,
      },
      {
        title: 'Mon profil',
        href: `/users/${me?.id}`,
        icon: <AccountBoxIcon />,
      },
      {
        title: 'Live IDE',
        href: '/playground',
        icon: <AccountBoxIcon />,
      },
      {
        title: 'Aventure',
        href: '/play',
        icon: <GamesIcon />,
      },
      // ...(() =>
      //   me?.roles?.length && me.roles.includes(Role.ADMIN)
      //     ? [
      //         {
      //           title: 'Avatars',
      //           href: '/avatars',
      //           icon: <FaceIcon />,
      //         },
      //       ]
      //     : [])(),
    ],
    [me],
  )

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
