import { useMutation } from '@apollo/react-hooks'
import {
  UpdateMyCodeDataInput,
  UpdateMyCardDataInput,
  UpdateMyChapterStatusInput,
  UpdateMyGameDataInput,
  UpdateMyQuestStatusInput,
} from 'types/globalTypes'
import {
  UpdateMyGameData,
  UpdateMyGameDataVariables,
} from './types/UpdateMyGameData'
import {
  UPDATE_MY_CODE_DATA,
  UPDATE_MY_CARD_DATA,
  UPDATE_MY_CHAPTERS_STATUS,
  UPDATE_MY_GAME_DATA,
  UPDATE_MY_QUEST_STATUS,
} from './mutation'
import {
  UpdateMyCodeData,
  UpdateMyCodeDataVariables,
} from './types/UpdateMyCodeData'
import {
  UpdateMyCardData,
  UpdateMyCardDataVariables,
} from './types/UpdateMyCardData'
import {
  UpdateMyChaptersStatus,
  UpdateMyChaptersStatusVariables,
} from './types/UpdateMyChaptersStatus'
import {
  UpdateMyQuestStatus,
  UpdateMyQuestStatusVariables,
} from './types/UpdateMyQuestStatus'

export const useUpdateGameData = () => {
  const [doGameDataUpdate, result] = useMutation<
    UpdateMyGameData,
    UpdateMyGameDataVariables
  >(UPDATE_MY_GAME_DATA)

  const mutate = (input: UpdateMyGameDataInput) => {
    doGameDataUpdate({ variables: { input } }).catch((error) => {
      const { graphQLErrors } = error
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const message = graphQLErrors[0].message
        console.error(message)
      }
    })
  }

  return { mutate, result }
}

export const useUpdateCodeData = () => {
  const [doCodeDataUpdate, result] = useMutation<
    UpdateMyCodeData,
    UpdateMyCodeDataVariables
  >(UPDATE_MY_CODE_DATA)

  const mutate = (input: UpdateMyCodeDataInput) => {
    console.log("Inside mutate")
    doCodeDataUpdate({ variables: { input } }).catch((error) => {
      const { graphQLErrors } = error
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const message = graphQLErrors[0].message
        console.error(message)
      }
    })
  }

  return { mutate, result }
}

export const useUpdateCardData = () => {
  const [doCardDataUpdate, result] = useMutation<
    UpdateMyCardData,
    UpdateMyCardDataVariables
  >(UPDATE_MY_CARD_DATA)

  const mutate = (input: UpdateMyCardDataInput) => {
    doCardDataUpdate({ variables: { input } }).catch((error) => {
      const { graphQLErrors } = error
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const message = graphQLErrors[0].message
        console.error(message)
      }
    })
  }

  return { mutate, result }
}

export const useUpdateChapterStatusData = () => {
  const [doChapterStatusDataUpdate, result] = useMutation<
    UpdateMyChaptersStatus,
    UpdateMyChaptersStatusVariables
  >(UPDATE_MY_CHAPTERS_STATUS)

  const mutate = (input: UpdateMyChapterStatusInput) => {
    doChapterStatusDataUpdate({ variables: { input } }).catch((error) => {
      const { graphQLErrors } = error
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const message = graphQLErrors[0].message
        console.error(message)
      }
    })
  }

  return { mutate, result }
}

export const useUpdateQuestStatusData = () => {
  const [doQuestStatusDataUpdate, result] = useMutation<
    UpdateMyQuestStatus,
    UpdateMyQuestStatusVariables
  >(UPDATE_MY_QUEST_STATUS)

  const mutate = (input: UpdateMyQuestStatusInput) => {
    doQuestStatusDataUpdate({ variables: { input } }).catch((error) => {
      const { graphQLErrors } = error
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const message = graphQLErrors[0].message
        console.error(message)
      }
    })
  }

  return { mutate, result }
}
