export const UnityAssetsPath =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? 'prod/' : 'dev/'
export const clientDomain = process.env.CLIENT_DOMAIN || ''
export const serverUri =
  // process.env.REACT_APP_SERVER_URI || 'http://localhost:8080/graphql'
  process.env.REACT_APP_SERVER_URI || 'https://backend.genius27.com/graphql'
export const restApiUri = process.env.REACT_APP_REST_API_URI
// export const mirrorHost = process.env.REACT_APP_MIRROR_HOST || 'localhost'
// export const mirrorHost = process.env.REACT_APP_MIRROR_HOST || 'genius27.com'
export const mirrorHost = 'genius27.com'
// export const mirrorHost = 'localhost'
// export const mirrorPort = process.env.REACT_APP_MIRROR_PORT || 7777
export const mirrorPort = 7777
