import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { serverUri } from './config'
import { resolvers, typeDefs } from './apolloCache'

export const createAppolloClient = () => {
  const cache = new InMemoryCache()

  const httpLink = createHttpLink({
    uri: serverUri,
  })

  // link used to send the accessToken
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: localStorage.getItem('token'),
      },
    }
  })

  // initial cache data
  const getInitialData = () => ({
    isLoggedIn: !!localStorage.getItem('token'),
  })

  // link used to handle common errors
  const errorHandlerlink = onError(
    ({ graphQLErrors, networkError, response }) => {
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const { message, locations, path } = graphQLErrors[0]

        if (
          message === 'EXPIRED_ACCESS_TOKEN' ||
          message === 'not authenticated'
        ) {
          localStorage.removeItem('token')
          response.errors = null
          cache.writeData({ data: getInitialData() })
        } else {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          )
        }
      }

      if (networkError) console.error(`[Network error]: ${networkError}`)
    },
  )

  // create our Apollo client
  const client = new ApolloClient({
    cache,
    link: errorHandlerlink.concat(authLink).concat(httpLink),
    resolvers,
    typeDefs,
  })

  // cache first initialization
  cache.writeData({
    data: getInitialData(),
  })

  client.onResetStore(() => {
    localStorage.removeItem('token')
    cache.writeData({ data: getInitialData() })
  })

  return client
}
