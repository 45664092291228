import { UnityMessagingData } from '../UnityPython' // Assuming UnityMessagingData is the interface or type containing the unity-related data
import { GetMe_me } from '../../../domains/user/types/GetMe'
import { mirrorHost, mirrorPort } from '../../../config'

const initPlayerValue = (
  unityMessagingData: UnityMessagingData,
  userInfo: GetMe_me | undefined,
) => {
  const { sendMessage } = unityMessagingData

  console.log('Init')
  console.log('unityMessagingData')
  console.log(unityMessagingData)
  console.log(userInfo)
  console.log('Init end')

  const refill = new Date(userInfo?.refillDate)
  sendMessage(
    'ReactLink',
    'InitPlayerValue',
    JSON.stringify({
      ...userInfo,
      name: userInfo?.nickname || userInfo?.username,
      refillDateString: `${refill.getFullYear()}-${handleLessThanTen(
        refill.getMonth() + 1,
      )}-${handleLessThanTen(refill.getDate())}T${handleLessThanTen(
        refill.getHours(),
      )}:${handleLessThanTen(refill.getMinutes())}:${handleLessThanTen(
        refill.getSeconds(),
      )}Z`,
      address: mirrorHost,
      port: mirrorPort,
    }),
  )
  console.log('Init sent')
}

const handleLessThanTen = (n: number) => {
  return n < 10 ? `0${n}` : n
}

export default initPlayerValue
