import React from 'react';

import { InputControl } from '..';
import UncontrolledArrayField from './UncontrolledArrayField';
import ControlledArrayField from './ControlledArrayField';

interface ArrayFieldProps {
  input: InputControl;
  control?: any;
  error: any;
  getValues: (payload?: string | string[]) => any;
  setValue: (arg0: string | { [name: string]: any }, value?: any, config?: Object) => void;
  register: any;
  isControlledMode?: boolean;
}

const ArrayField: React.FC<ArrayFieldProps> = (props) => {
  const { input, control, error, getValues, setValue, register, isControlledMode } = props;

  return !isControlledMode ? (
    <UncontrolledArrayField
      {...{
        input,
        error,
        control,
        getValues,
        setValue,
        register,
      }}
    />
  ) : (
    <ControlledArrayField
      {...{
        input,
        error,
        control,
        getValues,
        setValue,
        register,
      }}
    />
  );
};

export default ArrayField;
