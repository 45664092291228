import React, { useState, useEffect } from 'react';

function TextSlideshow() {
    const slides = [
      { id: 1, image: "/images/conseil.png",title: "Conseil", text: "Le conseil peut vous aider à éliminer les mauvaises réponses lors des questions posées par Genius" },
      { id: 2, image: "/images/temps.png", title: "Sablier de temps", text: "Le sablier de temps peut vous aider à rallonger votre temps de réponse lors des questions posées par Genius" },
      { id: 3, image: "/images/energie.png", title: "Énergie", text: "L'énergie permet de faire des quêtes et d'avancer dans le jeu. Un point d'énergie est consommé à chaque fois que vous entrez dans une quête. Se recharge toutes les 6 heures et s'achète également dans la boutique" },
      { id: 4, image: "/images/piece.png", title: "Pièces d'or", text: "Les pièces d'or permettent de faire des achats dans la boutique. Elles sont obtenues principalement en accomplissant des quêtes" },
      { id: 5, image: "/images/experience.png", title: "Points d'expérience", text: "Les points d'expérience s'obtiennent en achevant les quêtes avec succès. Une fois assez suffisant, votre personnage montera au niveau supérieur et vous débloquerez de nouvelles fonctionnalités" },
    ];
  
    const [currentSlide, setCurrentSlide] = useState(0);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setCurrentSlide((currentSlide + 1) % slides.length);
      }, 10000);
      return () => clearTimeout(timer);
    }, [currentSlide]);
  
    return (
      <div>
        <img src={slides[currentSlide].image} />
        <h1>{slides[currentSlide].title}</h1>
        <p>{slides[currentSlide].text}</p>
      </div>
    );
  }

  export default TextSlideshow;