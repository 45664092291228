import {
  useQuery,
  useMutation,
  MutationHookOptions,
} from '@apollo/react-hooks'
import { MutationResult, QueryResult } from '@apollo/react-common'
import { GET_ME, GET_USER, GET_USERS, IS_LOGGED_IN } from './query'
import {
  LOGIN,
  LOGOUT,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  SIGNUP,
  SSO,
} from './mutation'
import { GetUser, GetUserVariables, GetUser_user } from './types/GetUser'
import { GetUsers, GetUsers_users } from './types/GetUsers'
import { AddUser, AddUserVariables } from './types/AddUser'
import { AddUserInput, UpdateUserInput } from 'types/globalTypes'
import { UpdateUser, UpdateUserVariables } from './types/UpdateUser'
import { DeleteUser, DeleteUserVariables } from './types/DeleteUser'
import { GetMe, GetMe_me } from './types/GetMe'
import { Login, LoginVariables } from './types/Login'
import { IsLoggedIn } from './types/IsLoggedIn'
import { Logout } from './types/Logout'
import { Signup, SignupVariables } from './types/Signup'
import React from 'react'
import { Sso, SsoVariables } from './types/Sso'

export const useGetMe: () => [
  GetMe_me | undefined,
  Pick<QueryResult<GetMe>, 'loading' | 'error'>,
] = () => {
  const { data, loading, error } = useQuery<GetMe>(GET_ME)
  return [data?.me, { loading, error }]
}

export const useLogin = (
  options?: MutationHookOptions<Login, LoginVariables>,
) => {
  const [error, setError] = React.useState<string>('')
  const [doLogIn, { loading }] = useMutation<Login, LoginVariables>(
    LOGIN,
    options,
  )
  const mutate = (login: string, password: string) => {
    setError('')
    doLogIn({ variables: { input: { login, password } } }).catch((error) => {
      const { graphQLErrors, networkError } = error
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const message = graphQLErrors[0].message
        setError((val) => message)
      } else if (networkError) setError((val) => 'NETWORK_ERROR')
      else console.error('Unknown error')
    })
  }

  return [mutate, { loading, error }]
}

export const useSso = (options?: MutationHookOptions<Sso, SsoVariables>) => {
  const [error, setError] = React.useState<string>('')
  const [doSso, { loading }] = useMutation<Sso, SsoVariables>(SSO, options)

  const mutate = (firstName: string, lastName: string, email: string) => {
    setError('')
    doSso({ variables: { input: { lastName, firstName, email } } }).catch(
      (error) => {
        const { graphQLErrors, networkError } = error
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
          const message = graphQLErrors[0].message
          setError((val) => message)
        } else if (networkError) setError((val) => 'NETWORK_ERROR')
        else console.error('Unknown error')
      },
    )
  }

  return [mutate, { loading, error }]
}

export const useSignup = () => {
  const [error, setError] = React.useState<string>('')
  const [doSignup, { loading }] = useMutation<Signup, SignupVariables>(SIGNUP)

  const mutate = (input: SignupVariables) => {
    setError('')
    doSignup({ variables: { ...input } }).catch((error) => {
      const { graphQLErrors, networkError } = error
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const message = graphQLErrors[0].message
        setError(message)
      } else if (networkError) setError((val) => 'NETWORK_ERROR')
      else console.error('Unknown error')
    })
  }

  return [mutate, { loading, error }]
}

export const useLogout = () => {
  const [doLogout, result] = useMutation<Logout>(LOGOUT)

  const mutate = () => {
    doLogout({ variables: {} }).catch((error) => {
      const { graphQLErrors } = error
      if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
        const message = graphQLErrors[0].message
        console.error(message)
      }
    })
  }
  return { mutate, result }
}

export const useGetIsLoggedIn = () => {
  const { data } = useQuery<IsLoggedIn>(IS_LOGGED_IN)
  return data?.isLoggedIn
}

export const useGetUser: (
  id: string,
) => [
  GetUser_user | null | undefined,
  Pick<QueryResult<GetUser>, 'loading' | 'error'>,
] = (id) => {
  const { data, loading, error } = useQuery<GetUser>(GET_USER, {
    variables: { id },
  })
  return [data?.user, { loading, error }]
}

export const useGetUsers: () => [
  GetUsers_users[],
  Pick<QueryResult<GetUsers>, 'loading'>,
] = () => {
  const { data, loading } = useQuery<GetUsers>(GET_USERS)
  return [data?.users || [], { loading }]
}

export const useAddUser: (
  options?: MutationHookOptions<AddUser, AddUserVariables>,
) => [(input: AddUserInput) => void, MutationResult<AddUser>] = (options) => {
  const [doAddUser, result] = useMutation<AddUser, AddUserVariables>(
    ADD_USER,
    {
      update: (cache, { data }) => {
        const createdUser = data?.addUser

        try {
          const usersData = cache.readQuery<GetUsers>({
            query: GET_USERS,
          })
          if (createdUser && usersData?.users) {
            cache.writeQuery<GetUsers>({
              query: GET_USERS,
              data: { users: usersData.users.concat([createdUser]) },
            })
          }
        } catch {}
      },
      ...options,
    },
  )

  const mutate = (input: AddUserInput) => {
    doAddUser({ variables: { input } }).catch(
      ({ graphQLErrors, networkError }) => {
        if (graphQLErrors?.[0]?.message)
          console.error(graphQLErrors[0].message)
        else if (networkError) console.error('Network error')
        else console.error('Unknown error')
      },
    )
  }

  return [mutate, result]
}

export const useUpdateUser: (
  id: string,
  options?: MutationHookOptions<UpdateUser, UpdateUserVariables>,
) => [(input: UpdateUserInput) => void, MutationResult<UpdateUser>] = (
  id,
  options,
) => {
  const [doUpdateUser, result] = useMutation<UpdateUser, UpdateUserVariables>(
    UPDATE_USER,
    {
      update: (cache, { data }) => {
        if (data?.updateUser) {
          const updatedUser = data.updateUser

          try {
            const usersData = cache.readQuery<GetUsers>({
              query: GET_USERS,
            })
            if (updatedUser && usersData?.users) {
              cache.writeQuery<GetUsers>({
                query: GET_USERS,
                data: {
                  users: usersData.users.map((user) => {
                    return user.id !== updatedUser.id ? user : updatedUser
                  }),
                },
              })
            }
          } catch {}

          try {
            cache.writeQuery<GetUser, GetUserVariables>({
              query: GET_USER,
              variables: { id },
              data: {
                user: updatedUser,
              },
            })
          } catch {}
        }
      },
      ...options,
    },
  )

  const mutate = (input: UpdateUserInput) => {
    doUpdateUser({ variables: { id, update: input } }).catch(
      ({ graphQLErrors, networkError }) => {
        if (graphQLErrors?.[0]?.message)
          console.error(graphQLErrors[0].message)
        else if (networkError) console.error('Network error')
        else console.error('Unknown error')
      },
    )
  }

  return [mutate, result]
}

export const useDeleteUser: (
  options?: MutationHookOptions<DeleteUser, DeleteUserVariables>,
) => [(id: string) => void, MutationResult<DeleteUser>] = (options) => {
  const [doDeleteUser, result] = useMutation<DeleteUser, DeleteUserVariables>(
    DELETE_USER,
    {
      refetchQueries: [{ query: GET_USERS }],
      update: (cache, { data }) => {
        if (data?.deleteUser) {
          const currentUsersData = cache.readQuery<GetUsers>({
            query: GET_USERS,
          })

          if (currentUsersData && currentUsersData.users) {
            cache.writeQuery<GetUsers>({
              query: GET_USERS,
              data: {
                users: currentUsersData.users.filter(
                  (user) => user.id !== data.deleteUser,
                ),
              },
            })
          }
        }
      },
      ...options,
    },
  )

  const mutate = (id: string) => {
    doDeleteUser({ variables: { id } }).catch(
      ({ graphQLErrors, networkError }) => {
        if (graphQLErrors?.[0]?.message)
          console.error(graphQLErrors[0].message)
        else if (networkError) console.error('Network error')
        else console.error('Unknown error')
      },
    )
  }

  return [mutate, result]
}
