import gql from 'graphql-tag'
import fragment from './fragments'

export const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      ...LoginPayloadInfo
    }
    setLogInStatus @client(always: true)
  }
  ${fragment.LOGIN_PAYLOAD}
`

export const SIGNUP = gql`
  mutation Signup($input: SignupInput!) {
    signup(input: $input) {
      ...SignupPayloadInfo
    }
    setLogInStatus @client(always: true)
  }
  ${fragment.SIGNUP_PAYLOAD}
`

export const SSO = gql`
  mutation Sso($input: SsoInput!) {
    sso(input: $input) {
      ...SsoPayloadInfo
    }
    setLogInStatus @client(always: true)
  }
  ${fragment.SSO_PAYLOAD}
`

export const CHECK_TOKEN = gql`
  mutation CheckToken($input: CheckTokenInput!) {
    checkToken(input: $input) {
      email
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`

export const LOGOUT = gql`
  mutation Logout {
    logout @client
  }
`

export const ADD_USER = gql`
  mutation AddUser($input: AddUserInput!) {
    addUser(input: $input) {
      ...FragmentUserDetails
    }
  }
  ${fragment.DETAILS}
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $update: UpdateUserInput!) {
    updateUser(id: $id, update: $update) {
      ...FragmentUserDetails
    }
  }
  ${fragment.DETAILS}
`

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`
