import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { RouteWithLayout } from './components'
import { Minimal as MinimalLayout } from './layouts'
import { Main as MainLayout } from './layouts'

import {
  NotFound as NotFoundView,
  Users as UsersView,
  SignIn as SignInView,
  SignUp as SignUpView,
  // Formations as FormationsView,
  Playground as PlaygroundView,
  Unity as UnityView,
  UnityHtml as UnityHtmlView,
  UnityJavascript as UnityJavascriptView,
  UnityPython as UnityPythonView,
  UnityDatascience as UnityDatascienceView,
} from './views'
import { PasswordResetView } from './views/PasswordReset'

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/play" />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
        noCheckSession
      />
      <RouteWithLayout
        component={PasswordResetView}
        exact
        layout={MinimalLayout}
        layoutProps={{ withLogoutBtn: false }}
        path="/password-reset/:resetToken"
        noCheckSession
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
        noCheckSession
      />
      <RouteWithLayout
        component={UsersView}
        layout={MainLayout}
        path="/users"
      />
      {/*
      <RouteWithLayout
        component={FormationsView}
        layout={MainLayout}
        path="/formations"
      />*/}
      <RouteWithLayout
        component={PlaygroundView}
        exact
        layout={MinimalLayout}
        layoutProps={{ withLogoutBtn: true }}
        path="/playground"
      />
      <RouteWithLayout
        component={UnityView}
        exact
        layout={MinimalLayout}
        layoutProps={{ withLogoutBtn: true }}
        path="/play"
      />
      <RouteWithLayout
        component={UnityHtmlView}
        exact
        layout={MinimalLayout}
        layoutProps={{ withLogoutBtn: true }}
        path="/html"
      />
      <RouteWithLayout
        component={UnityJavascriptView}
        exact
        layout={MinimalLayout}
        layoutProps={{ withLogoutBtn: true }}
        path="/javascript"
      />
      <RouteWithLayout
        component={UnityPythonView}
        exact
        layout={MinimalLayout}
        layoutProps={{ withLogoutBtn: true }}
        path="/python"
      />
      <RouteWithLayout
        component={UnityDatascienceView}
        exact
        layout={MinimalLayout}
        layoutProps={{ withLogoutBtn: true }}
        path="/datascience"
      />
      <RouteWithLayout component={NotFoundView} layout={MinimalLayout} />
    </Switch>
  )
}

export default Routes
