import { GetRanking_rankings } from 'domains/leaderboard/types/GetRanking'
import { UnityMessagingData } from '../Unity'

const getRanking = (
  unityMessagingData: UnityMessagingData,
  rankingData: GetRanking_rankings[] | undefined,
) => {
  const { sendMessage } = unityMessagingData
  console.log('rankingData react')
  console.log(JSON.stringify(rankingData))
  sendMessage('ReactLink', 'OnRankingResponse', JSON.stringify(rankingData))
}

export default getRanking
