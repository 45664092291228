import gql from 'graphql-tag'
import fragment from './fragments'

export const GET_ME = gql`
  query GetMe {
    me {
      ...FragmentUserDetails
    }
  }
  ${fragment.DETAILS}
`

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...FragmentUserDetails
    }
  }
  ${fragment.DETAILS}
`

export const GET_USERS = gql`
  query GetUsers {
    users {
      ...FragmentUserDetails
    }
  }
  ${fragment.DETAILS}
`

export const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    isLoggedIn @client(always: true)
  }
`

export const FIND_EMAIL = gql`
  query FindEMail($input: FindEmailInput!) {
    findEMail(input: $input) {
      success
    }
  }
`
