/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Role defines the rights of the users.
 * Ex: Administrator has the right to do everything.
 * Employee has the right to edit only his own data.
 */
export enum Role {
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
}

export interface AddActionLogInput {
  actiondate: any;
  actiontype: string;
  actionvalue: string;
}

export interface AddUserInput {
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName?: string | null;
}

export interface CheckTokenInput {
  token: string;
}

export interface FindEmailInput {
  email: string;
}

export interface GetActionLogsInput {
  startDate: any;
  endDate: any;
}

export interface LoginInput {
  login: string;
  password: string;
}

export interface ReportBugInput {
  obj: string;
  description: string;
}

export interface ResetPasswordInput {
  token: string;
  email: string;
  password: string;
}

export interface SignupInput {
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName?: string | null;
}

export interface SsoInput {
  lastName?: string | null;
  firstName: string;
  email: string;
}

export interface UpdateMyCardDataInput {
  cardIndex: number;
  cardLife?: number | null;
  cardAttack?: number | null;
  cardMana?: number | null;
}

export interface UpdateMyChapterStatusInput {
  languageIndex: number;
  questIndex: number;
  chapterIndex: number;
  isComplete?: boolean | null;
  isUnlocked?: boolean | null;
  correctAnswers?: number | null;
  wrongAnswers?: number | null;
  responseTime?: number | null;
  score?: number | null;
}

export interface UpdateMyCodeDataInput {
  languageIndex: number;
  name: string;
  code?: string | null;
}

export interface UpdateMyGameDataInput {
  nickname?: string | null;
  avatarIndex?: number | null;
  level?: number | null;
  health?: number | null;
  gold?: number | null;
  diamond?: number | null;
  energy?: number | null;
  tip?: number | null;
  time?: number | null;
  tries?: number | null;
  music?: boolean | null;
  sfx?: boolean | null;
  volume?: number | null;
  maxExperience?: number | null;
  refillDate?: any | null;
  lives?: number | null;
  showFirstTutorial?: boolean | null;
  showSecondTutorial?: boolean | null;
  pythonTestDone?: boolean | null;
  showCardTutorial?: boolean | null;
  showIntroduction?: boolean | null;
  showDashboardIntroduction?: boolean | null;
  showVillageDestruction?: boolean | null;
  showLabyrinthScene?: boolean | null;
  cardGameLife?: number | null;
  skillPoints?: number | null;
}

export interface UpdateMyQuestStatusInput {
  languageIndex: number;
  questIndex: number;
  isComplete?: boolean | null;
  showCompletion?: boolean | null;
  correctAnswers?: number | null;
  wrongAnswers?: number | null;
  responseTime?: number | null;
  score?: number | null;
}

export interface UpdateUserInput {
  username?: string | null;
  email?: string | null;
  password?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  roles?: Role[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
