import React from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'

import CustomButton from '../CustomButton'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  paper: {
    '& .MuiPaper-elevation24	': {
      boxShadow: 'none',
    },
  },
  grid: {
    height: '100%',
    maxHeight: '100%',
    alignItems: 'center',
  },
  contentContainer: {},
  content: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    padding: theme.spacing(0, 8),
    paddingBottom: theme.spacing(6),
    flexBasis: 640,
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formBox: {
    border: '8px solid #ffffff',
    borderRadius: theme.spacing(8),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(5),
    backgroundColor: '#E48657',
  },
  titleBox: {
    border: '8px solid #ffffff',
    borderRadius: theme.spacing(8),
    padding: theme.spacing(1),
    backgroundColor: '#E48657',
    bottom: -theme.spacing(5),
    width: 320,
    alignSelf: 'center',
    position: 'relative',
    margin: 'auto',
  },
  title: {
    fontFamily: 'GillSansUltraBold',
    color: 'white',
    textAlign: 'center',
  },
  unityElement: {
    fontFamily: 'GillSansUltraBold',
    borderRadius: theme.spacing(4),
    border: '4px solid #ffffff',
    fontSize: theme.spacing(4),
    padding: 0,
  },
  unityLabel: {
    color: 'white',
    backgroundColor: '#E48657',
    padding: theme.spacing(1),
    border: '2px solid #ffffff',
    borderRadius: 16,
  },
  noBorder: {
    border: 'none',
  },
  btnClose: {
    position: 'absolute',
    right: theme.spacing(9),
    bottom: theme.spacing(7),
    background: "url('/images/close.png') no-repeat",
    backgroundSize: 'cover',
    width: theme.spacing(8),
    height: theme.spacing(8),
  },

  confirmButton: {
    margin: 0,
    height: 54,
    marginTop: theme.spacing(4),
    '& .MuiButton-label': {
      textTransform: 'none',
      height: 48,
    },
  },
}))
interface ConfirmDialogProps {
  title: string
  contentText: string
  onClose: () => void
  onConfirm: () => void
  confirmBtnLoading?: boolean
}

const ConfirmDialog: React.FC<DialogProps & ConfirmDialogProps> = (props) => {
  const {
    onClose,
    title,
    contentText,
    onConfirm,
    confirmBtnLoading,
    ...other
  } = props
  const classes = useStyles()

  return (
    <Dialog
      className={classes.paper}
      classes={{ paper: classes.root }}
      {...{
        ...other,
        'aria-labelledby': 'confirm-dialog',
      }}
    >
      <Box padding={2}>
        <div className={classes.contentBody}>
          <Box className={classes.formContainer}>
            <Box className={classes.titleBox}>
              <Typography className={classes.title} variant="h2">
                {title}
              </Typography>
            </Box>
            <Box className={classes.form}>
              <Box className={classes.formBox}>
                <DialogContent>
                  <DialogContentText
                    className={classes.title}
                    variant="h3"
                    id="alert-dialog-description"
                  >
                    {contentText}
                    <CustomButton
                      className={clsx(
                        classes.confirmButton,
                        classes.unityElement,
                      )}
                      color="primary"
                      fullWidth
                      size="medium"
                      variant="contained"
                      onClick={onConfirm}
                    >
                      Confirmer
                    </CustomButton>
                  </DialogContentText>
                  <IconButton
                    className={classes.btnClose}
                    onClick={() => {
                      onClose()
                    }}
                  />
                </DialogContent>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
    </Dialog>
  )
}

export default ConfirmDialog
