import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles, createStyles } from '@material-ui/core/styles'

const styles = createStyles({
  loading: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const CustomButton = React.forwardRef<any, any>((props, ref) => {
  const { classes, children, loading = false, loadingSize, ...others } = props

  return (
    <Button {...others} ref={ref}>
      {children}
      {loading && (
        <div className={classes.loading}>
          <CircularProgress size={loadingSize} />
        </div>
      )}
    </Button>
  )
})

CustomButton.propTypes = {
  loading: PropTypes.bool,
  loadingSize: PropTypes.number,
  children: PropTypes.node.isRequired,
}

CustomButton.defaultProps = {
  loading: false,
  loadingSize: 32,
}

export default withStyles(styles)(CustomButton)
