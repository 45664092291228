import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'

type HeaderProps = {
  title: string
  right?: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(1),
    border: '1px solid #555555',
    display: 'flex',
  },
  right: {
    color: 'white',
  },
  h5: {
    color: 'white',
    flex: 1,
  },
}))

const Header: React.FC<HeaderProps> = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <Typography variant="h5" align="center" className={classes.h5}>
        {props.title}
      </Typography>
      {props?.right && <Box className={classes.right}>{props?.right}</Box>}
    </Box>
  )
}
export default Header
