import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { InputControl } from '..';
import ControlRenderer from '../ControlRenderer';

interface ArrayFieldProps {
  input: InputControl;
  control: any;
  error: any;
  getValues: (payload?: string | string[]) => any;
  setValue: (arg0: string | { [name: string]: any }, value?: any, config?: Object) => void;
  register: any;
}

const ControlledArrayField: React.FC<ArrayFieldProps> = (props) => {
  const { input, control, error, getValues, setValue, register } = props;

  const fieldsValues: any[] = Array.isArray(getValues(input.name)) ? getValues(input.name) : [];

  const append = () => setValue(input.name, [...fieldsValues, null]);

  const remove = (index: number) =>
    setValue(input.name, [...fieldsValues.slice(0, index), ...fieldsValues.slice(index + 1)]);

  const handleOnChange: InputControl['onChange'] = (value, getValues, setValue, childInput) => {
    const index = +childInput.name.split('.')[1];
    setValue(input.name, [
      ...fieldsValues.slice(0, index),
      value,
      ...fieldsValues.slice(index + 1),
    ]);
  };

  return (
    <React.Fragment>
      {fieldsValues.map((value, index) => (
        <Grid key={`value-${index}}`} item xs={12} container wrap="nowrap">
          <FormControl error={!!error && !!error[index]} fullWidth>
            <ControlRenderer
              {...{
                input: {
                  ...input,
                  name: `${input.name}.${index}`,
                  defaultValue: value,
                  onChange: handleOnChange,
                },
                register,
                getValues,
                setValue,
                control,
                error: !!error && !!error[index],
                isControlledMode: true,
              }}
            />

            {!!error && !!error[index]?.value?.message && (
              <FormHelperText>{error[index].value.message}</FormHelperText>
            )}
          </FormControl>

          <Box marginLeft={1}>
            <IconButton
              disabled={input.readOnly}
              aria-label="delete"
              onClick={(e) => remove(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>
      ))}

      <Box marginLeft={1}>
        <IconButton disabled={input.readOnly} aria-label="add" onClick={(e) => append()}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
    </React.Fragment>
  );
};

export default ControlledArrayField;
