import React, { useState, useEffect } from 'react'
import useStyles from 'views/SignIn/SignIn.styles'

type VideoSlideshowLoginProps = {
  imageProgress1?: (progression: number) => void
  imageProgress2?: (progression: number) => void
  imageProgress3?: (progression: number) => void
  imageProgress4?: (progression: number) => void
  imageProgress5?: (progression: number) => void
  imageProgress6?: (progression: number) => void
  imageProgress7?: (progression: number) => void
}

const VideoSlideshowLogin: React.FC<VideoSlideshowLoginProps> = ({
  imageProgress1,
  imageProgress2,
  imageProgress3,
  imageProgress4,
  imageProgress5,
  imageProgress6,
  imageProgress7,
}) => {
  const classes = useStyles()
  const slides = [
    { id: 1, image: '/images/1.png' },
    { id: 3, image: '/images/3.png' },
    { id: 4, image: '/images/4.PNG' },
    { id: 5, image: '/images/5.png' },
    { id: 6, image: '/images/6.PNG' },
    { id: 2, image: '/images/7.png' },
    { id: 7, image: '/images/2.png' },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)

  useEffect(() => {
    const progressTimer = setInterval(() => {
      setCurrentProgress(currentProgress + 1)
      switch (currentSlide) {
        case 0:
          if (imageProgress7) imageProgress7(0)
          if (imageProgress1) imageProgress1(100)
          break
        case 1:
          if (imageProgress1) imageProgress1(0)
          if (imageProgress2) imageProgress2(100)
          break
        case 2:
          if (imageProgress2) imageProgress2(0)
          if (imageProgress3) imageProgress3(100)
          break
        case 3:
          if (imageProgress3) imageProgress3(0)
          if (imageProgress4) imageProgress4(100)
          break
        case 4:
          if (imageProgress4) imageProgress4(0)
          if (imageProgress5) imageProgress5(100)
          break
        case 5:
          if (imageProgress5) imageProgress5(0)
          if (imageProgress6) imageProgress6(100)
          break
        case 6:
          if (imageProgress6) imageProgress6(0)
          if (imageProgress7) imageProgress7(100)
          break
      }
      if (currentProgress === 100) {
        setCurrentProgress(0)
        setCurrentSlide((currentSlide + 1) % slides.length)
      }
    }, 40)

    return () => {
      clearInterval(progressTimer)
    }
  }, [currentSlide, currentProgress, slides.length])

  return (
    <div className={classes.videoContainer}>
      <img src={slides[currentSlide].image} />
    </div>
  )
}

export default VideoSlideshowLogin
