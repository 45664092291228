import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'

import ControlRenderer from './ControlRenderer'
import ArrayField from './ArrayField'

const styles = (theme: Theme) =>
  createStyles({
    actionButtonsWrap: {
      marginTop: theme.spacing(3),
      '&>*+*': {
        marginLeft: theme.spacing(1),
      },
    },

    formControl: {},

    formControlLabel: {
      ...theme.typography.body1,
      fontWeight: 500,
    },

    fieldArrayWrap: {
      margin: theme.spacing(0, 2, 0, 0),
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  })

export interface InputControl {
  name: string
  label: string
  placeholder?: string
  component:
    | 'TextField'
    | 'Select'
    | 'Date'
    | 'File'
    | 'CheckBoxes'
    | 'RadioGroup'
    | React.ComponentType<{
        onChange: (value: any) => void
        error?: boolean
        readOnly?: boolean
      }>
  defaultValue?: any
  rules?: any
  options?: (
    | {
        label?: string
        value: any
      }
    | string
  )[]
  isArray?: boolean
  onChange?: (
    value: any,
    getValues: (payload?: string | string[]) => any,
    setValue: (
      arg0: string | { [name: string]: any },
      value?: any,
      config?: Object,
    ) => void,
    input: InputControl,
  ) => void
  componentProps?: any
  readOnly?: boolean
}

export interface CustomFormControlProps extends WithStyles<typeof styles> {
  input: InputControl
  getValues: (payload?: string | string[]) => any
  setValue: (
    arg0: string | { [name: string]: any } | { [name: string]: any },
    value?: any,
    config?: Object,
  ) => void
  control?: any
  error?: any
  register: any
  isControlledMode?: boolean
}

const CustomFormControl: React.FC<CustomFormControlProps> = (props) => {
  const {
    classes,
    input,
    getValues,
    setValue,
    control,
    error,
    register,
    isControlledMode,
  } = props

  return (
    <FormControl error={!!error} className={classes.formControl}>
      <FormLabel htmlFor={input.name} className={classes.formControlLabel}>
        {input.label}
      </FormLabel>

      {input.isArray ? (
        <Grid
          id={input.name}
          container
          spacing={2}
          className={classes.fieldArrayWrap}
        >
          <ArrayField
            {...{
              input,
              error,
              control,
              getValues,
              setValue,
              register,
              isControlledMode,
            }}
          />
        </Grid>
      ) : (
        <ControlRenderer
          {...{
            input,
            register,
            getValues,
            setValue,
            control,
            error,
            isControlledMode,
          }}
        />
      )}

      {!!error?.message && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  )
}

export default withStyles(styles as any)(CustomFormControl)
