import React from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';

import { useUncontrolledCustomForm as useCustomForm, CustomFormSections, FormSection } from '.';

const styles = () =>
  createStyles({
    divider: {},
    formControl: {},
    actionButtonsWrap: {},
    sectionContent: {},
  });

export interface CustomFormBuilderProps extends WithStyles<typeof styles> {
  formContent: FormSection[];
  onCancel?: Function;
  onFormCancel?: Function;
  onSubmit?: (data: any) => void;
  onFormSubmit?: (data: any) => void;
  submitLoading?: boolean;
  resetButtonText?: string;
  submitButtonText?: string;
  noResetBtn?: boolean;
  noSubmitBtn?: boolean;
}

const CustomFormBuilder: React.FC<CustomFormBuilderProps> = (props) => {
  const {
    classes,
    formContent,
    onSubmit,
    onFormSubmit,
    onCancel,
    onFormCancel,
    resetButtonText,
    submitButtonText,
    noResetBtn,
    noSubmitBtn,
    submitLoading,
  } = props;

  const useCustomFormMethods = useCustomForm({ formContent, onSubmit: onSubmit ?? onFormSubmit });

  return (
    <CustomFormSections
      {...{
        formContent,
        classes,
        onCancel: onCancel ?? onFormCancel,
        resetButtonText,
        submitButtonText,
        noResetBtn,
        noSubmitBtn,
        submitLoading,
        useCustomFormMethods,
      }}
    />
  );
};

export default withStyles(styles)(CustomFormBuilder);
