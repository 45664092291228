import React from 'react';
import YouTube from 'react-youtube';

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoId: props.videoId
    };

    this.handleClick = this.handleClick.bind(this);
    this._onReady = this._onReady.bind(this);
  }

  handleClick() {
    this.setState({
      showPlayer: true
    });
  }

  render() {
    const opts = {
      width: '1280',
      height: '720',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };
    console.log("Video Player Log")
    return (
        <YouTube videoId={this.state.videoId} opts={opts} onReady={this._onReady}/>
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }
}

export default VideoPlayer;