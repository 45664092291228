import {
  useQuery,
  useMutation,
  MutationHookOptions,
} from '@apollo/react-hooks'
import { MutationResult, QueryResult } from '@apollo/react-common'
import { GET_RANKING } from './query'
import { GetRanking, GetRanking_rankings } from './types/GetRanking'

export const useGetRanking: () => [
  GetRanking_rankings[],
  Pick<QueryResult<GetRanking>, 'loading'>,
] = () => {
  const { data, loading } = useQuery<GetRanking>(GET_RANKING)
  return [data?.rankings || [], { loading }]
}

export const useGetRankingByLanguage: () => [
  GetRanking_rankings[],
  Pick<QueryResult<GetRanking>, 'loading'>,
] = () => {
  const { data, loading } = useQuery<GetRanking>(GET_RANKING)
  return [data?.rankings || [], { loading }]
}
