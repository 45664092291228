import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useGetIsLoggedIn } from 'domains/user/useUser'

const LayoutWithCheckSession = (props) => {
  const { layout: Layout, layoutProps, noCheckSession = false } = props
  const isLoggedIn = useGetIsLoggedIn()

  if (!isLoggedIn && !noCheckSession) return <Redirect to="/sign-in" />

  return <Layout {...layoutProps}>{props.children}</Layout>
}

const RouteWithLayout = (props) => {
  const {
    layout,
    layoutProps,
    component: Component,
    noCheckSession,
    ...rest
  } = props

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LayoutWithCheckSession {...{ layout, layoutProps, noCheckSession }}>
          <Component {...matchProps} />
        </LayoutWithCheckSession>
      )}
    />
  )
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  layoutProps: PropTypes.any,
  path: PropTypes.string,
}

export default RouteWithLayout
