import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';

import UsersList from './UsersList';
import UserDetails from './UserDetails';

const Users = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route exact path={`${match.path}/`} component={UsersList} />
      <Route exact path={`${match.path}/:userId`} component={UserDetails} />
    </>
  );
};

export default Users;
