import React from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { CustomButton } from '../index'
import { useLazyQuery } from '@apollo/react-hooks'
import { FIND_EMAIL } from '../../domains/user/query'
import {
  FindEMail,
  FindEMailVariables,
} from '../../domains/user/types/FindEMail'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  paper: {
    '& .MuiPaper-elevation24	': {
      boxShadow: 'none',
    },
  },
  grid: {
    height: '100%',
    maxHeight: '100%',
    alignItems: 'center',
  },
  contentContainer: {},
  content: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    padding: theme.spacing(0, 8),
    paddingBottom: theme.spacing(6),
    flexBasis: 800,
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formBox: {
    border: '8px solid #ffffff',
    borderRadius: theme.spacing(8),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(5),
    backgroundColor: '#E48657',
  },
  titleBox: {
    border: '8px solid #ffffff',
    borderRadius: theme.spacing(8),
    padding: theme.spacing(1),
    backgroundColor: '#E48657',
    bottom: -theme.spacing(5),
    width: 320,
    alignSelf: 'center',
    position: 'relative',
    margin: 'auto',
  },
  title: {
    fontFamily: 'GillSansUltraBold',
    color: 'white',
    textAlign: 'center',
  },
  error: {
    fontFamily: 'GillSansUltraBold',
    color: 'red',
    textAlign: 'center',
  },
  success: {
    fontFamily: 'GillSansUltraBold',
    color: 'green',
    textAlign: 'center',
  },
  unityElement: {
    fontFamily: 'GillSansUltraBold',
    borderRadius: theme.spacing(4),
    border: '4px solid #ffffff',
    fontSize: theme.spacing(4),
    padding: 0,
  },
  unityLabel: {
    color: 'white',
    backgroundColor: '#E48657',
    padding: theme.spacing(1),
    border: '2px solid #ffffff',
    borderRadius: 16,
  },
  signInButton: {
    margin: 0,
    height: 54,
    marginTop: theme.spacing(2),
    '& .MuiButton-label': {
      textTransform: 'none',
      height: 48,
    },
  },
  noBorder: {
    border: 'none',
  },
  btnClose: {
    position: 'absolute',
    right: theme.spacing(9),
    bottom: theme.spacing(7),
    background: "url('/images/close.png') no-repeat",
    backgroundSize: 'cover',
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  textField: {
    marginTop: theme.spacing(2),
    '& .MuiOutlinedInput-input': {
      padding: 8,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center',
    },
    '& .MuiInputBase-input': {
      color: 'white!important',
      fontFamily: 'GillSansUltraBold',
      fontSize: '1.5rem',
    },
    '& input[type="password"]': {
      // fontFamily: 'fontello',
      // fontWeight: 'bold',
      speak: 'none',

      /* For safety - reset parent styles, that can break glyph codes*/
      fontVariant: 'normal',
      // textTransform: 'none',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
    '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active ': {
      WebkitTextFillColor: 'white',
      WebkitBackgroundClip: 'text',
    },
  },
}))

interface PasswordResetEmailDialogProps {
  onClose: () => void
}

const PasswordResetEmailDialog: React.FC<
  DialogProps & PasswordResetEmailDialogProps
> = (props) => {
  const { onClose: handleClose, ...other } = props
  const { register, errors, getValues, reset } = useForm()
  const [error, setError] = React.useState({ message: '', success: false })
  const [findEmail, { loading }] = useLazyQuery<FindEMail, FindEMailVariables>(
    FIND_EMAIL,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setError({
          message: ERROR_MESSAGE.LINK_SENT,
          success: true,
        })
        reset()
      },
      onError: (error) => {
        if (error?.message.endsWith('no documents in result')) {
          setError({
            message: ERROR_MESSAGE.UNKNOWN_EMAIL,
            success: false,
          })
        } else {
          setError({
            message: ERROR_MESSAGE.UNKNOWN_ERROR,
            success: false,
          })
        }
      },
    },
  )

  const classes = useStyles()
  const checkEmailInput = () => {
    findEmail({ variables: { input: { email: getValues('email') } } })
  }
  return (
    <Dialog
      className={classes.paper}
      classes={{ paper: classes.root }}
      {...{
        ...other,
        'aria-labelledby': 'confirm-dialog',
      }}
      maxWidth="md"
    >
      <Box padding={2}>
        <div className={classes.contentBody}>
          <Box className={classes.formContainer}>
            <Box className={classes.titleBox}>
              <Typography className={classes.title} variant="h2">
                Entrez votre adresse email
              </Typography>
            </Box>
            <Box className={classes.form}>
              <Box className={classes.formBox}>
                <DialogContent style={{ width: 640 }}>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <TextField
                      className={clsx(classes.textField, classes.unityElement)}
                      fullWidth
                      placeholder={'Email'}
                      name="email"
                      variant="outlined"
                      disabled={error?.success}
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      inputRef={register({ required: true })}
                      error={!!errors.email}
                    />
                    {!loading && error?.message && (
                      <Box marginTop={2}>
                        <Typography
                          className={
                            error.success ? classes.success : classes.error
                          }
                          variant="body1"
                        >
                          {error?.message}
                        </Typography>
                      </Box>
                    )}
                    <Box width={280}>
                      <CustomButton
                        className={clsx(
                          classes.signInButton,
                          classes.unityElement,
                        )}
                        color="primary"
                        fullWidth
                        size="medium"
                        variant="contained"
                        loading={loading}
                        onClick={
                          error?.success ? handleClose : checkEmailInput
                        }
                      >
                        {error?.success ? 'OK' : 'Vérifier'}
                      </CustomButton>
                    </Box>
                  </Box>
                  <IconButton
                    className={classes.btnClose}
                    onClick={() => {
                      handleClose()
                    }}
                  />
                </DialogContent>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
    </Dialog>
  )
}

export default PasswordResetEmailDialog

const ERROR_MESSAGE = {
  LINK_SENT:
    "Un lien pour la réinitialisation a été envoyé à l'adresse email indiqué",
  UNKNOWN_ERROR: 'Erreur de serveur, veuillez réessayer',
  UNKNOWN_EMAIL: 'Adresse email introuvable',
}
