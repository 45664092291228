import React, { useState, useEffect } from 'react'

type ImageSlideshowProps = {
  imageProgress1?: (progression: number) => void
  imageProgress2?: (progression: number) => void
  imageProgress3?: (progression: number) => void
  imageProgress4?: (progression: number) => void
  imageProgress5?: (progression: number) => void
}

const ImageSlideshow: React.FC<ImageSlideshowProps> = ({
  imageProgress1,
  imageProgress2,
  imageProgress3,
  imageProgress4,
  imageProgress5,
}) => {
  const slides = [
    { id: 1, image: '/images/loading/loading-1.png' },
    { id: 2, image: '/images/loading/loading-2.png' },
    { id: 3, image: '/images/loading/loading-3.png' },
    { id: 4, image: '/images/loading/loading-4.png' },
    { id: 5, image: '/images/loading/loading-5.png' },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)

  useEffect(() => {
    const progressTimer = setInterval(() => {
      setCurrentProgress(currentProgress + 1)
      switch (currentSlide) {
        case 0:
          if (imageProgress5) imageProgress5(0)
          if (imageProgress1) imageProgress1(100)
          break
        case 1:
          if (imageProgress1) imageProgress1(0)
          if (imageProgress2) imageProgress2(100)
          break
        case 2:
          if (imageProgress2) imageProgress2(0)
          if (imageProgress3) imageProgress3(100)
          break
        case 3:
          if (imageProgress3) imageProgress3(0)
          if (imageProgress4) imageProgress4(100)
          break
        case 4:
          if (imageProgress4) imageProgress4(0)
          if (imageProgress5) imageProgress5(100)
          break
      }
      if (currentProgress === 100) {
        setCurrentProgress(0)
        setCurrentSlide((currentSlide + 1) % slides.length)
      }
    }, 50)

    return () => {
      clearInterval(progressTimer)
    }
  }, [currentSlide, currentProgress, slides.length])

  return (
    <div>
      <img src={slides[currentSlide].image} />
    </div>
  )
}

export default ImageSlideshow

// import React, { useState, useEffect } from 'react';

// function ImageSlideshow() {
//   const slides = [
//     { id: 1, image: "/images/loading/loading-1.png" },
//     { id: 2, image: "/images/loading/loading-2.png" },
//     { id: 3, image: "/images/loading/loading-3.png" },
//     { id: 4, image: "/images/loading/loading-4.png" },
//     { id: 5, image: "/images/loading/loading-5.png" },
//   ];

//   const [currentSlide, setCurrentSlide] = useState(0);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setCurrentSlide((currentSlide + 1) % slides.length);
//     }, 5000);
//     return () => clearTimeout(timer);
//   }, [currentSlide, slides.length]);

//   return (
//     <div>
//       <img src={slides[currentSlide].image} alt={slides[currentSlide].title} />
//     </div>
//   );
// }

// export default ImageSlideshow;
