import React, { memo } from 'react'
import useStyles from './Playground.styles'
import HtmlCss from '../ChapterTwo/HtmlCss/HtmlCss'

const Playground: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <HtmlCss chapter={4} />
    </div>
  )
}

export default memo(Playground)
