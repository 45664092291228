import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import { useLogout } from '../../../../domains/user/useUser'
import { ConfirmDialog } from '../../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  logo: {
    maxWidth: 200,
    color: 'white',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}))

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props
  const [showLogoutConfirmation, setShowLogoutConfirmation] =
    React.useState(false)
  const [doLogout] = useLogout()

  const classes = useStyles()

  const handleLogOut = () => {
    doLogout().catch(() => {})
  }
  const handleLogoutShow = () => {
    setShowLogoutConfirmation((current) => !current)
  }
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/" className={classes.logo}>
          <Typography variant="h3" component="h1" color="inherit">
            Diris
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        {/* <Hidden mdDown> */}
        <IconButton
          onClick={handleLogoutShow}
          className={classes.signOutButton}
          color="inherit"
        >
          <PowerSettingsNewIcon />
        </IconButton>
        {/* </Hidden> */}
        {/* <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
      <ConfirmDialog
        onClose={handleLogoutShow}
        title={'Confirmation'}
        onConfirm={handleLogOut}
        contentText={'Êtes-vous sûr de vouloir vous déconnecter?'}
        open={showLogoutConfirmation}
      />
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar
