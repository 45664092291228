import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    // marginTop: '50vh',
    // marginLeft: '50vw',
    // transform: 'translate(-50%,-50%)',
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 9999,
  },
  videoBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1 /* Make sure the video is behind all other content */,
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // adjust the opacity as needed
      zIndex: 1,
    },
  },
  video: {},
  TextSlider: {
    marginTop: theme.spacing(40),
    width: '100%',
    height: 200,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    padding: theme.spacing(2),
    fontFamily: 'Xolonium-Regular',
    paddingTop: theme.spacing(3),
    color: 'white',
    '& h1': {
      fontSize: '2.5em',
    },
    '& p': {
      marginTop: 25,
      fontSize: '1.5em',
    },
    '& img': {
      width: '30px',
      height: '30px',
      marginBottom: theme.spacing(2),
    },
  },
  ImageSlider: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    zIndex: -1,
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  ProgressBar: {
    position: 'absolute',
    backgroundColor: '#303030',
    bottom: 10,
    height: 10,
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImagesProgressContainer: {
    position: 'absolute',
    bottom: 30,
    height: 15,
    width: '100%',
  },
  ImageProgress1: {
    position: 'absolute',
    width: '10px',
    left: '40%',
    height: '0.75em',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress2: {
    position: 'absolute',
    width: '10px',
    left: '45%',
    height: '0.75em',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress3: {
    position: 'absolute',
    width: '10px',
    left: '50%',
    height: '0.75em',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress4: {
    position: 'absolute',
    width: '10px',
    left: '55%',
    height: '0.75em',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress5: {
    position: 'absolute',
    width: '10px',
    left: '60%',
    height: '0.75em',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
}))

export default useStyles
