import React from 'react'
import { Grid } from '@material-ui/core'
import pythonStyles from './Python.styles'
import Header from './components/Section/Header'
import Content from './components/Section/Content'
import { Editor } from '../../../components/Editor'
import Button from '@material-ui/core/Button'
import { ReactUnityEventParameter } from 'react-unity-webgl/distribution/types/react-unity-event-parameters'

type PythonMultiplayerProps = {
  sendMessage: (
    gameObjectName: string,
    methodName: string,
    parameter?: ReactUnityEventParameter,
  ) => void
}

const PythonMultiplayer: React.FC<PythonMultiplayerProps> = ({
  sendMessage,
}) => {
  const classes = pythonStyles()
  const [userCode, setUserCode] = React.useState('')
  const onCodeChange = (editorCode: string) => {
    setUserCode(editorCode)
  }

  function validate() {
    console.log('Python multiplayer')
    sendMessage('Command', 'Validate', userCode)
  }

  return (
    <Grid container className={classes.root} direction="row" wrap={'nowrap'}>
      <Grid
        item
        md={6}
        className={classes.editor}
        container
        direction="column"
      >
        <Header title="EDITEUR" />
        <Content>
          <Editor
            lang="python"
            onChange={onCodeChange}
            initialData={''}
            height="90%"
          />
          <Button
            onClick={validate}
            style={{ position: 'relative', left: '50%', top: '10px' }}
          >
            Valider
          </Button>
        </Content>
      </Grid>
    </Grid>
  )
}

export default PythonMultiplayer
