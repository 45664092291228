import React from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useForm } from 'react-hook-form'

import { CustomButton } from '../../components'
import { useGetIsLoggedIn } from '../../domains/user/useUser'
import useStyles from './PasswordReset.styles'
import { Helmet } from 'react-helmet'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import InfoDialog from '../../components/InfoDialog/InfoDialog'
import {
  usePasswordRecover,
  useTokenCheck,
} from '../../domains/user/usePasswordRecover'
import { CircularProgress } from '@material-ui/core'
import { ApolloError } from 'apollo-client'

interface IFormData {
  email: string
  password: string
  passwordConfirm?: string
}

const PasswordReset = () => {
  const classes = useStyles()
  const { resetToken } = useParams<{ resetToken: string }>()
  const [openDialog, setOpenDialog] = React.useState(false)
  const [messageText, setMessageText] = React.useState('')
  const { register, errors, handleSubmit } = useForm()
  const isLoggedIn = useGetIsLoggedIn()
  const history = useHistory()
  const tokenCheckOnError = (error: ApolloError) => {
    const { graphQLErrors, networkError } = error
    if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
      const message = graphQLErrors[0].message
      switch (message) {
        case 'redis: nil':
          setMessageText('Jeton de réinitialisation invalide')
          break
        case 'mongo: no documents in result':
          setMessageText('Jeton ou adresse email invalide')
          break
      }
    } else if (networkError)
      setMessageText((val) => 'Erreur de réseau, veuillez réessayer')
    else setMessageText((val) => 'Erreur de réseau, veuillez réessayer')
    setOpenDialog(true)
  }
  const [doTokenCheck, { loading: doTokenCheckLoading }] = useTokenCheck(
    tokenCheckOnError,
  )

  const [
    doPasswordRecover,
    { loading: doRecoverLoading, error: recoverError },
  ] = usePasswordRecover(tokenCheckOnError)

  const onFormSubmit = (data: IFormData) => {
    const { password, passwordConfirm } = data
    setMessageText('')
    if (password !== passwordConfirm) {
      setMessageText('Les deux mots de passe sont différents')
      setOpenDialog(true)
      return
    }
    delete data?.passwordConfirm
    doPasswordRecover({ input: { ...data, token: resetToken } })
  }

  React.useEffect(() => {
    if (!resetToken) {
      history.push('/sign-in')
    }
    doTokenCheck({ input: { token: resetToken } })
  }, [])

  React.useEffect(() => {
    if (recoverError) {
      console.log(recoverError)
      setOpenDialog(true)
      switch (recoverError) {
        case 'NETWORK_ERROR':
          setMessageText('Erreur de réseau,\nveuillez réessayer')
          break
        case 'username already used':
          setMessageText("Nom d'utilisateur non disponible")
          break
        case 'email already used':
          setMessageText('Un compte associé à cet adresse email existe déjà')
          break
        default:
          setMessageText('Un erreur est survenu,\nveuillez réessayer')
          break
      }
    }
  }, [recoverError])

  if (isLoggedIn) return <Redirect to="/" />

  const handleInfoClose = () => {
    setOpenDialog(false)
    setMessageText('')
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Réinitialisation de mot de passe</title>
      </Helmet>
      {doTokenCheckLoading ? (
        <CircularProgress size={64} />
      ) : (
        <Grid container>
          <Grid container>
            <Grid container className={classes.content} item xs={12}>
              <div className={classes.content}>
                <div className={classes.contentBody}>
                  <Box className={classes.formContainer}>
                    <Box className={classes.titleBox}>
                      <Typography className={classes.title} variant="h2">
                        Réinitialisez votre mot de passe
                      </Typography>
                    </Box>
                    <form
                      className={classes.form}
                      onSubmit={handleSubmit(onFormSubmit)}
                    >
                      <Box className={classes.formBox}>
                        <TextField
                          className={clsx(
                            classes.textField,
                            classes.unityElement,
                          )}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder },
                          }}
                          fullWidth
                          placeholder="Adresse email"
                          name="email"
                          type="text"
                          variant="outlined"
                          inputRef={register({ required: true })}
                          error={!!errors.email}
                        />
                        {/*<TextField*/}
                        {/*  className={clsx(*/}
                        {/*    classes.textField,*/}
                        {/*    classes.unityElement,*/}
                        {/*  )}*/}
                        {/*  InputProps={{*/}
                        {/*    classes: { notchedOutline: classes.noBorder },*/}
                        {/*  }}*/}
                        {/*  fullWidth*/}
                        {/*  placeholder="Nom d'utilisateur"*/}
                        {/*  name="username"*/}
                        {/*  type="text"*/}
                        {/*  variant="outlined"*/}
                        {/*  inputRef={register({ required: true })}*/}
                        {/*  error={!!errors.username}*/}
                        {/*/>*/}
                        <TextField
                          className={clsx(
                            classes.textField,
                            classes.unityElement,
                          )}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder },
                          }}
                          fullWidth
                          placeholder="Mot de passe"
                          name="password"
                          type="password"
                          variant="outlined"
                          inputRef={register({ required: true })}
                          error={!!errors.password}
                        />
                        <TextField
                          className={clsx(
                            classes.textField,
                            classes.unityElement,
                          )}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder },
                          }}
                          fullWidth
                          placeholder="Confirmation"
                          name="passwordConfirm"
                          type="password"
                          variant="outlined"
                          inputRef={register({ required: true })}
                          error={!!errors.passwordConfirm}
                        />

                        <CustomButton
                          className={clsx(
                            classes.signInButton,
                            classes.unityElement,
                          )}
                          color="primary"
                          fullWidth
                          size="medium"
                          type="submit"
                          variant="contained"
                          loading={doRecoverLoading || doTokenCheckLoading}
                        >
                          Envoyer
                        </CustomButton>
                      </Box>
                    </form>
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
      {openDialog && (
        <InfoDialog
          onClose={handleInfoClose}
          title={'Erreur'}
          contentText={messageText}
          open={true}
        />
      )}
    </div>
  )
}

export default PasswordReset
