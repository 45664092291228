import React, { Component } from 'react'
import { Router, useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ApolloProvider } from '@apollo/react-hooks'
import AppContext from 'AppContext'
import BackgroundMusic from 'components/BackgroundMusic'
import BackgroundVideo from 'components/BackgroundVideo'

import { createAppolloClient } from './appolloClient'
import theme from './theme'
import './assets/scss/index.scss'
import Routes from './Routes'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_ID } from 'utils/constants'

const browserHistory = createBrowserHistory()
const appolloClient = createAppolloClient()

const ScrollToTop = () => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default class App extends Component {
  state = {
    videoPlaying: true,
    musicPlaying: true,
  }

  setVideo = (state) => {
    this.setState({ videoPlaying: state })
  }

  setMusic = (state) => {
    this.setState({ musicPlaying: state })
  }

  toggleVideo = () => {
    this.setState({ videoPlaying: !this.state.videoPlaying })
    console.log('Video toggled')
  }

  toggleMusic = () => {
    this.setState({ musicPlaying: !this.state.musicPlaying })
    console.log('Music toggled')
  }

  render() {
    const { videoPlaying, musicPlaying } = this.state

    return (
      <GoogleOAuthProvider clientId={GOOGLE_ID}>
        <AppContext.Provider
          value={{
            videoPlaying,
            musicPlaying,
            setVideo: this.setVideo,
            setMusic: this.setMusic,
            toggleMusic: this.toggleMusic,
          }}
        >
          {/* {videoPlaying && <BackgroundVideo />} */}
          {musicPlaying && <BackgroundMusic />}
          <ApolloProvider client={appolloClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router history={browserHistory}>
                <ScrollToTop />
                <Routes />
              </Router>
            </ThemeProvider>
          </ApolloProvider>
        </AppContext.Provider>
      </GoogleOAuthProvider>
    )
  }
}
