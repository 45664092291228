import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    width: '350px',
    height: '110px',
    // marginLeft: theme.spacing(4),
    backgroundImage: "url('/images/genius.png')",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  formContainer: {
    position: 'absolute',
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    // paddingBottom: theme.spacing(6),
    // flexBasis: 640,
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2),
    // },
    height: '100%',
    backgroundColor: '#131A15DD',
    // flexBasis: 400,
    overflowY: 'scroll',
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formBox: {
    // border: '8px solid #ffffff',
    // borderRadius: theme.spacing(8),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    // paddingTop: theme.spacing(5),
    // backgroundColor: '#E48657',
  },
  titleBox: {
    // border: '8px solid #ffffff',
    // borderRadius: theme.spacing(8),
    // padding: theme.spacing(1),
    // backgroundColor: '#E48657',
    // bottom: -theme.spacing(6),
    width: 320,
    alignSelf: 'center',
    position: 'relative',
    margin: 'auto',
  },
  title: {
    fontFamily: 'Xolonium-Regular',
    fontSize: '20px',
    color: 'white',
    textAlign: 'left',
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  suggestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(1),
    '& .MuiOutlinedInput-input': {
      padding: 15,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center',
    },
    '& .MuiInputBase-input': {
      color: 'white!important',
      fontFamily: 'Xolonium-Regular',
      fontSize: '0.75rem',
    },
    '& input[type="password"]': {
      // fontFamily: 'fontello',
      // fontWeight: 'bold',
      speak: 'none',

      /* For safety - reset parent styles, that can break glyph codes*/
      fontVariant: 'normal',
      // textTransform: 'none',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
    '& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active ':
      {
        WebkitTextFillColor: 'white',
        WebkitBackgroundClip: 'text',
      },
  },
  signInButton: {
    // background: 'none',
    // backgroundImage: "url('/images/Button.png')",
    // backgroundSize: 'contain',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    // margin: 0,
    // height: 45,
    // width: 250,
    // marginTop: theme.spacing(2),
    // boxShadow: 'none',
    // '& .MuiButton-label': {
    //   textTransform: 'none',
    //   height: 48,
    //   fontFamily: 'Xolonium-Regular',
    //   fontSize: '.9em',
    // },

    background: 'none',
    backgroundImage: "url('/images/Button.png')",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 45,
    width: 250,
    borderRadius: '5px',
    display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'space-evenly',
    // alignItems: 'center',
    // gap: '10px',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    '& .MuiButton-label': {
      textTransform: 'none',
      height: 48,
      fontFamily: 'Xolonium-Regular',
      fontSize: '.9em',
    },
  },
  ssoButton: {
    background: 'none',
    backgroundImage: "url('/images/Button.png')",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 45,
    width: 250,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    '& .MuiButton-label': {
      textTransform: 'none',
      height: 48,
      fontFamily: 'Xolonium-Regular',
      fontSize: '.9em',
    },
  },
  toggleMusicButton: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    width: 30,
    height: 30,
    borderRadius: 25,
  },
  unityElement: {
    width: '100%',
    height: theme.spacing(5),
    fontFamily: 'Xolonium-Regular',
    fontSize: '1.5rem',
    padding: 0,
    backgroundImage: "url('/images/input.png')",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  unityLabel: {
    color: 'white',
    backgroundColor: '#E48657',
    padding: theme.spacing(1),
    border: '2px solid #ffffff',
    borderRadius: 16,
  },
  noBorder: {
    border: 'none',
  },
  videoBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1 /* Make sure the video is behind all other content */,
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  label: {
    marginTop: '20px',
    fontSize: '15px',
    fontFamily: 'Xolonium-Regular',
    color: 'white',
  },
  recommandation: {
    marginTop: '20px',
    fontSize: '10px',
    fontFamily: 'Xolonium-Regular',
    color: 'white',
    textAlign: 'center',
  },
  horizontalRule: {
    width: '75%',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    marginLeft: '12.5%',
  },
  link: {
    background: 'none',
    color: '#D1DF8C',
    fontFamily: 'Xolonium-Regular',
    fontSize: '1em',
    boxShadow: 'none',
    display: 'block',
    marginLeft: theme.spacing(3),
  },
  giris: {
    width: '50px',
    height: '50px',
    backgroundImage: "url('/images/giris.png')",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'absolute',
    bottom: theme.spacing(-20),
    right: theme.spacing(4),
  },
  genius: {
    width: '100px',
    height: '100px',
    backgroundImage: "url('/images/genius-character.png')",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(4),
  },
  youtubeContainer: {
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    overflow: 'hidden',
    marginBottom: '50px',
    backgroundColor: '#000000AA',
    top: 0,
    right: 0,
    '& iframe': {
      width: '75%',
      height: '75%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '& .MuiButton-root': {
      position: 'absolute',
      top: '5%',
      right: '0',
      transform: 'translate(-50%, -50%)',
    },
  },
  videoContainer: {
    width: '100%',
    height: '100%',
  },
  ImageSlider: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    zIndex: -1,
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  ImagesProgressContainer: {
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(3),
    width: '100%',
  },
  ImageProgress1: {
    position: 'absolute',
    width: '10px',
    left: '25%',
    height: '0.75em',
    borderRadius: '50px',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress2: {
    position: 'absolute',
    width: '10px',
    left: '30%',
    height: '0.75em',
    borderRadius: '50px',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress3: {
    position: 'absolute',
    width: '10px',
    left: '35%',
    height: '0.75em',
    borderRadius: '50px',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress4: {
    position: 'absolute',
    width: '10px',
    left: '40%',
    height: '0.75em',
    borderRadius: '50px',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress5: {
    position: 'absolute',
    width: '10px',
    left: '45%',
    height: '0.75em',
    borderRadius: '50px',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress6: {
    position: 'absolute',
    width: '10px',
    left: '50%',
    height: '0.75em',
    borderRadius: '50px',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
  ImageProgress7: {
    position: 'absolute',
    width: '10px',
    left: '55%',
    height: '0.75em',
    borderRadius: '50px',
    backgroundColor: '#303030',
    '& .MuiLinearProgress-bar': {
      transition: 'none',
      backgroundColor: '#FFEC59',
    },
  },
}))

export default useStyles
