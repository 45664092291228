import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ChromePicker } from 'react-color';
const rgba = require('color-rgba');

const useStyles = makeStyles(() => ({
  colorPicker: {
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 3px 0px!important',
  },
}));

const ColorPickerPopper = (props) => {
  const classes = useStyles();
  const { onClose, onSave, rgbaColor } = props;

  const [r, g, b, a] = rgba(rgbaColor);
  const initialRgbColor = { r, g, b, a };
  const [color, setColor] = React.useState(initialRgbColor);

  const onChange = (color) => {
    setColor(color.rgb);
  };

  const handleSave = () => {
    onSave(`rgba(${color.r},${color.g},${color.b},${color.a})`);
  };

  return (
    <Dialog
      {...{
        onClose,
        'aria-labelledby': 'color-picker-dialog-title',
        open: true,
        BackdropProps: { invisible: true },
      }}
    >
      <DialogTitle id="color-picker-dialog-title" disableTypography>
        <Typography variant="h5" component="h2">
          Choisissez une couleur
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ChromePicker
          {...{
            color,
            onChange,
            className: classes.colorPicker,
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined" size="small">
          Annuler
        </Button>
        <Button onClick={handleSave} color="secondary" variant="contained" size="small">
          Sauvegarder
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ColorPickerPopper.propTypes = {
  rgbaColor: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

ColorPickerPopper.defaultProps = {
  rgbaColor: 'rgba(100,87,239,1.0)',
};

export default ColorPickerPopper;
