import React from 'react'
import { Controller } from 'react-hook-form'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

import { InputControl } from '.'

interface ControlRendererProps {
  input: InputControl
  register: any
  getValues: (payload?: string | string[]) => any
  setValue: (
    arg0: string | { [name: string]: any },
    value?: any,
    config?: Object,
  ) => void
  control?: any
  error?: any
  isControlledMode?: boolean
}

const ControlRenderer: React.FC<ControlRendererProps> = (props) => {
  const {
    input,
    register,
    getValues,
    setValue,
    control,
    error,
    isControlledMode,
  } = props

  const getControlledValue = () => {
    const parsedInputName = input.name.split('.')
    return !input.isArray
      ? getValues(input.name) || ''
      : getValues(parsedInputName[0])?.[parsedInputName[1]] || ''
  }

  const textFieldCommonProps: TextFieldProps = {
    name: input.name,
    id: input.name,
    error: !!error,
    variant: 'outlined',
    placeholder: input.placeholder,
    onChange: (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      let value: string | number = event.target.value
      if (input.componentProps?.inputProps?.type === 'number') {
        const parsedValue = parseInt(value, 10)
        value = isNaN(parsedValue) ? 0 : parsedValue
      }

      if (input.onChange) input.onChange(value, getValues, setValue, input)
      else if (isControlledMode) setValue(input.name, value)
    },
    ...input.componentProps,
    inputProps:
      typeof input.readOnly === 'boolean'
        ? {
            ...input.componentProps?.inputProps,
            readOnly: input.readOnly,
          }
        : input.componentProps?.inputProps,
    defaultValue: isControlledMode ? undefined : input.defaultValue || '',
    inputRef: input.rules ? register(input.rules) : register,
    value: isControlledMode
      ? input.component === 'File'
        ? ''
        : getControlledValue()
      : undefined,
  }

  React.useEffect(() => {
    if (typeof input.component !== 'string' && !isControlledMode)
      register(input.name, input.rules)
  }, [input, register, isControlledMode])

  if (typeof input.component === 'string')
    switch (input.component) {
      case 'TextField':
        return <TextField {...textFieldCommonProps} />

      case 'Select':
        if (!input.options) input.options = []
        return !isControlledMode ? (
          <Controller
            {...{
              as: (
                <TextField select>
                  <MenuItem value="">
                    <em>Choisir</em>
                  </MenuItem>

                  {input.options.map((option) => (
                    <MenuItem
                      key={typeof option === 'string' ? option : option.value}
                      value={
                        typeof option === 'string' ? option : option.value
                      }
                    >
                      {typeof option === 'string'
                        ? option
                        : option.label || option.value}
                    </MenuItem>
                  ))}
                </TextField>
              ),
              control,
              name: input.name,
              id: input.name,
              rules: input.rules,
              error: !!error,
              variant: 'outlined',
              placeholder: input.placeholder,
              ...input.componentProps,
              inputProps:
                typeof input.readOnly === 'boolean'
                  ? {
                      ...input.componentProps?.inputProps,
                      readOnly: input.readOnly,
                    }
                  : input.componentProps?.inputProps,
            }}
          />
        ) : (
          <TextField {...textFieldCommonProps} select>
            <MenuItem value="">
              <em>Choisir</em>
            </MenuItem>

            {input.options.map((option) => (
              <MenuItem
                key={typeof option === 'string' ? option : option.value}
                value={typeof option === 'string' ? option : option.value}
              >
                {typeof option === 'string'
                  ? option
                  : option.label || option.value}
              </MenuItem>
            ))}
          </TextField>
        )

      case 'Date':
        return (
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            {...textFieldCommonProps}
          />
        )

      case 'File':
        return (
          <TextField
            type="file"
            InputLabelProps={{ shrink: true }}
            {...textFieldCommonProps}
          />
        )

      case 'CheckBoxes':
        if (!input.options) input.options = []
        return (
          <FormGroup row {...input.componentProps}>
            {input.options.map((option, i) => (
              <FormControlLabel
                key={typeof option === 'string' ? option : option.value}
                control={
                  <Checkbox
                    name={`${input.name}_${i}`}
                    inputRef={register}
                    inputProps={{
                      disabled: input.readOnly,
                      value:
                        typeof option === 'string' ? option : option.value,
                    }}
                    /*      
                    onChange={(event: any, checked) => {
                      console.log(getValues(input.name))
                      const currentValue = getValues(input.name) || []
                      checked && currentValue.append(event?.target?.value)
                      setValue(input.name, new Set(...currentValue))
                    }}
                    checked={
                      getValues(input.name)
                        ? getValues(input.name).some((v: string) =>
                            typeof option === 'string'
                              ? option === v
                              : option.value === v,
                          )
                        : false
                    }
                    */
                  />
                }
                label={
                  typeof option === 'string'
                    ? option
                    : option.label || option.value
                }
              />
            ))}
          </FormGroup>
        )

      case 'RadioGroup':
        if (!input.options) input.options = []
        return !isControlledMode ? (
          <Controller
            {...{
              as: (
                <RadioGroup
                  style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                  aria-label={input.name}
                >
                  {input.options.map((option) => (
                    <FormControlLabel
                      key={typeof option === 'string' ? option : option.value}
                      value={
                        typeof option === 'string' ? option : option.value
                      }
                      control={
                        <Radio
                          disabled={input.readOnly ?? undefined}
                          color="primary"
                        />
                      }
                      label={
                        typeof option === 'string'
                          ? option
                          : option.label || option.value
                      }
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              ),
              control,
              name: input.name,
              id: input.name,
              rules: input.rules,
              ...input.componentProps,
            }}
          />
        ) : (
          <RadioGroup
            {...{
              name: input.name,
              id: input.name,
              value: isControlledMode ? getControlledValue() : undefined,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (input.onChange)
                  input.onChange(
                    event.target.value,
                    getValues,
                    setValue,
                    input,
                  )
                else if (isControlledMode)
                  setValue(input.name, event.target.value)
              },
              ...input.componentProps,
            }}
            style={{ flexDirection: 'row', flexWrap: 'wrap' }}
            aria-label={input.name}
          >
            {input.options.map((option) => (
              <FormControlLabel
                key={typeof option === 'string' ? option : option.value}
                value={typeof option === 'string' ? option : option.value}
                control={
                  <Radio
                    disabled={input.readOnly ?? undefined}
                    color="primary"
                  />
                }
                label={
                  typeof option === 'string'
                    ? option
                    : option.label || option.value
                }
                labelPlacement="end"
              />
            ))}
          </RadioGroup>
        )

      default:
        throw new Error(
          `InputControl.component must be one of: 'TextField', 'Select', 'Date', 'File', 'CheckBoxes', got : ${input.component}`,
        )
    }
  else
    return (
      <input.component
        {...{
          id: input.name,
          name: input.name,
          onChange: (value) => {
            if (input.isArray) {
              const index = +input.name.split('.')[1]

              const left = getValues(input.name)?.slice(0, index)
              const right = getValues(input.name)?.slice(index + 1)
              const newValue: any[] = []
              if (left) newValue.concat(left)
              newValue.concat(value)
              if (right) newValue.concat(right)

              if (input.onChange)
                input.onChange(value, getValues, setValue, input)
              else setValue(input.name, newValue)
            } else setValue(input.name, value)
            if (input.onChange)
              input.onChange(value, getValues, setValue, input)
            else setValue(input.name, value)
          },
          error: !!error,
          value: isControlledMode ? getControlledValue() : undefined,
          readOnly: input.readOnly,
          isArray: input.isArray,
          ...input.componentProps,
        }}
      />
    )
}

export default ControlRenderer
