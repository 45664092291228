import gql from 'graphql-tag'

export const GET_RANKING = gql`
  query GetRanking {
    rankings {
      name
      avatarIndex
      score
    }
  }
`

export const GET_RANKING_BY_LANGUAGE = gql`
  query GetRankingByLanguage($input: Int!) {
    rankingsByLanguage(input: $input) {
      name
      avatarIndex
      score
    }
  }
`
