import React from 'react'
import Box from '@material-ui/core/Box'
import { useParams, Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useGetUser } from '../../../domains/user/useUser'

import TabDisplay from '../../../components/TabDisplay'
import { UserInfos } from './components'

const UserDetails: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()

  const [
    user,
    { loading: getUserQueryLoading, error: getUserQueryError },
  ] = useGetUser(userId)

  if (
    getUserQueryError?.graphQLErrors[0]?.extensions?.response?.status === 404
  ) {
    return (
      <Box padding={2}>
        <Typography variant="h4" component="h1" paragraph>
          Utilisateur non trouvé
        </Typography>
        <Typography
          component={Link}
          to="/users"
          style={{ textDecoration: 'underline' }}
        >
          Voir tous les utilisateurs
        </Typography>
      </Box>
    )
  }
  if (getUserQueryLoading) return <CircularProgress />

  const userTabs = [
    {
      label: 'Utilisateur',
      content: <UserInfos {...{ user }} />,
    },
  ]

  return (
    <Box padding={2}>
      <TabDisplay tabs={userTabs} ariaLabel="User-details" />
    </Box>
  )
}

export default UserDetails
