import React, { useEffect, useRef, useState } from 'react'

function BackgroundMusic() {
  const audioRef = useRef(null)
  const [volume, setVolume] = useState(0.1)

  const handleUserInteraction = () => {
    if (audioRef.current) {
      audioRef.current.play()
    }
    // remove the event listener to avoid multiple playbacks on subsequent user interactions
    document.removeEventListener('click', handleUserInteraction)
  }

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio('/audios/background.mp3')
      audioRef.current.loop = true
      // Set initial volume
      audioRef.current.volume = volume
      audioRef.current.play()
      // add a click event listener to the document to handle the user interaction
      document.addEventListener('click', handleUserInteraction)
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.pause()
      }
      // remove the event listener when unmounting the component
      document.removeEventListener('click', handleUserInteraction)
    }
  }, [])

  return null
}

export default BackgroundMusic
