import { useForm } from 'react-hook-form';

import { FormSection, CustomInput } from '../CustomFormSections';
import { formSectionsToCustomInputs } from './utils';

const useCustomForm: (options: {
  formContent: FormSection[];
  onSubmit?: (data: any) => void;
}) => any = (options) => {
  const { formContent, onSubmit } = options;

  const inputs = formSectionsToCustomInputs(formContent);
  const defaultValues = getDefaultValues(inputs);

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    errors,
    reset,
    ...useFormRest
  } = useForm({
    defaultValues,
  });

  const handleFormSubmit = (data: any) => {
    /* since useFieldArray don't support flat arrays (https://react-hook-form.com/api/#useFieldArray),
       we design array fields values as objects of type : {value : any} */
    const arrayFieldsInputNames = inputs
      .filter((input) => input.isArray)
      .map((input) => input.name);

    for (let index = 0; index < arrayFieldsInputNames.length; index++) {
      const inputName = arrayFieldsInputNames[index];

      data[inputName] = Array.isArray(data[inputName])
        ? data[inputName].map((item: { value: any }) => item.value)
        : [];
    }

    if (onSubmit) onSubmit(data);
  };

  return {
    ...useFormRest,
    register,
    control,
    getValues,
    setValue,
    errors,
    reset,
    handleSubmit: handleSubmit(handleFormSubmit), // TODO, => make handleFormSubmit a func that take annother func (data: any)=> void, and make it return handleSubmit
    isControlledMode: false,
  };
};

export const getDefaultValues = (inputs: CustomInput[]) => {
  const defaultValues: { [name: string]: any } = {};
  for (let index = 0; index < inputs.length; index++) {
    const input = inputs[index];

    defaultValues[input.name] = input.isArray
      ? Array.isArray(input.defaultValue)
        ? input.defaultValue.map((value) => ({
            value,
          }))
        : []
      : input.defaultValue || '';
  }

  return defaultValues;
};

export default useCustomForm;
