import React from 'react'
import 'ace-builds/src-noconflict/ace'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-golang'
// import 'ace-builds/src-noconflict/snippets/html'
// import 'ace-builds/src-noconflict/snippets/java'
// import 'ace-builds/src-noconflict/snippets/javascript'
// import 'ace-builds/src-noconflict/snippets/python'
// import 'ace-builds/src-noconflict/snippets/golang'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/theme-xcode'

type EditorProps = {
  lang: 'html' | 'java' | 'javascript' | 'golang' | 'python'
  theme?: 'dracula' | 'monokai' | 'xcode'
  onChange?: (code: string) => void
  initialData?: string
  height: string
}

const Editor: React.FC<EditorProps> = ({
  lang,
  theme = 'dracula',
  onChange,
  initialData,
  height,
}) => {
  const onEditorchange = React.useCallback((newValue: string) => {
    if (onChange) onChange(newValue)
  }, [onChange])

  return (
    <AceEditor
      mode={lang}
      theme={theme}
      fontSize={12}
      onChange={onEditorchange}
      width={'100%'}
      height={height}
      defaultValue={initialData}
      editorProps={{ $blockScrolling: true }}
      highlightActiveLine={true}
      showPrintMargin={true}
      name="html-editor"
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
        useWorker: false,
      }}
    />
  )
}
export default Editor
