import React from 'react'
import { Grid } from '@material-ui/core'
import pythonStyles from './Python.styles'
import Header from './components/Section/Header'
import Content from './components/Section/Content'
import { Editor } from '../../../components/Editor'
import Button from '@material-ui/core/Button'
import { ReactUnityEventParameter } from 'react-unity-webgl/distribution/types/react-unity-event-parameters'
import {
  runCode,
  setEngine,
  setOptions,
  getVariable,
  getVariables,
  setVariable,
} from 'client-side-python-runner'

type PythonProps = {
  sendMessage: (
    gameObjectName: string,
    methodName: string,
    parameter?: ReactUnityEventParameter,
  ) => void
  lockDatas: string[]
  generateSpawnPoints: boolean
  passwordDigits: string
  ticTacToeMatrix: number[]
  remainingAmmo: number
  codeValidation: boolean
}

const Python: React.FC<PythonProps> = ({
  sendMessage,
  lockDatas,
  generateSpawnPoints,
  passwordDigits,
  ticTacToeMatrix,
  remainingAmmo,
  codeValidation,
}) => {
  const classes = pythonStyles()
  const [userCode, setUserCode] = React.useState('')
  const onCodeChange = (editorCode: string) => {
    setUserCode(editorCode)
  }

  const methods = [
    // Labyrinth
    'MoveRight',
    'MoveLeft',
    'MoveUp',
    'MoveDown',
    'LogMessage',
    'Collect',
    'Attack',
    'CheckEnemy',
    'TryUnlock',
    'AddPlayerRoute',
    'StartPlayerMove',
    'TryUnlockString',
    'CheckCase',
    'SelectCase',
    'Validate',
    'PlaceShip',
    'ValidatePlacement',
    'InitiateFire',
    'ValidateFires',
  ]

  async function validate() {
    sendMessage('Python Manager', 'InterpretInput', userCode)
  }

  async function evaluateCode() {
    sendMessage('Violet Discussion Manager', 'EvaluateCode', userCode)
  }

  async function validateOld() {
    await setVariable('__codeResult', '')
    await setVariable('parameter', '')
    await setVariable('locks', lockDatas)
    await setVariable('passwordDigits', passwordDigits)
    await setVariable('_ammo', remainingAmmo)

    let spawnPoints = []
    if (generateSpawnPoints) {
      sendMessage('Command', 'ClearPlayerRoute')
      console.log('Generate points: ')
      // for (let i = 0; i < 5; i++){
      //   let row = Math.floor(Math.random() * 6) + 1;
      //   let column = Math.floor(Math.random() * 6) + 1;
      //   console.log('Spawn point: ' + row + ' , ' + column)
      //   sendMessage("Command", "AddSpawnPoint", (row + "_" + column))
      //   spawnPoints.push([row, column])
      // }
      sendMessage('Command', 'AddSpawnPoint', 2 + '-' + 2)
      sendMessage('Command', 'AddSpawnPoint', 3 + '-' + 3)
      sendMessage('Command', 'AddSpawnPoint', 4 + '-' + 4)
      sendMessage('Command', 'AddSpawnPoint', 5 + '-' + 5)
      sendMessage('Command', 'AddSpawnPoint', 6 + '-' + 6)
      spawnPoints.push([2, 2])
      spawnPoints.push([3, 3])
      spawnPoints.push([4, 4])
      spawnPoints.push([5, 5])
      spawnPoints.push([6, 6])
      await setVariable('spawns', spawnPoints)
    }

    let code = `
__codeResult = []
parameter = []

right = [1, 0]
left = [-1, 0]
up = [0, 1]
down = [0, -1]

playerRow = 0
playerColumn = 0
foodRow = 0
foodColumn = 0
currentDirection = [1, 0]

def moveRight(steps = 1):
    global __codeResult
    __codeResult.append(0)
    parameter.append(steps)
  
def moveLeft(steps = 1):
    global __codeResult
    __codeResult.append(1)
    parameter.append(steps)
  
def moveUp(steps = 1):
    global __codeResult
    __codeResult.append(2)
    parameter.append(steps)
  
def moveDown(steps = 1):
    global __codeResult
    __codeResult.append(3)
    parameter.append(steps)
  
def log(message):
    global __codeResult
    __codeResult.append(4)
    parameter.append(message)
  
def collect():
    global __codeResult
    __codeResult.append(5)
    parameter.append(' ')
  
def attack(enemy):
    global __codeResult
    __codeResult.append(6)
    parameter.append(enemy)
  
def checkEnemy(direction):
    global __codeResult
    __codeResult.append(7)
    parameter.append(direction)

def validateLocks():
    global __codeResult
    __codeResult.append(8)
    firstLock = shiftLetters(locks[0]);
    secondLock = shiftLetters(locks[1]);
    thirdLock = shiftLetters(locks[2]);
    parameter.append(firstLock + secondLock + thirdLock)

def setDirection(direction):
    currentDirection[0] = direction[0]
    currentDirection[1] = direction[1]

def validateMove():
    global __codeResult
    playerRow = 0
    playerColumn = 0
    foodRow = spawns[0][0]
    foodColumn = spawns[0][1]
    for x in range(50):
      behavior()
      playerRow += currentDirection[1]
      playerColumn += currentDirection[0]
      __codeResult.append(9)
      parameter.append(str(playerRow) + '-' + str(playerColumn))
      if playerRow == foodRow and playerColumn == foodColumn:
        foodRow = spawns[1][0]
        foodColumn = spawns[1][1]
    __codeResult.append(10)

def testDigit(digitToTest, digitIndex):
    digit = passwordDigits[digitIndex]
    digit = int(digit)
    return digitToTest == digit

def validate(func):
    global __codeResult
    __codeResult.append(14)
    parameter.append(func)

def placeShip(shipIndex, rowIndex, columnIndex, direction):
    global __codeResult
    directionIndex = 0
    if direction == 'down':
        directionIndex = 1
    elif direction == 'left':
        directionIndex = 2
    elif direction == 'right':
        directionIndex = 3
    __codeResult.append(15)
    parameter.append(str(shipIndex) + "_" + str(rowIndex) + "_" + str(columnIndex) + "_" + str(directionIndex))

def validatePlacement():
    global __codeResult
    __codeResult.append(16)
    parameter.append('')
    print('Placement Validated')

def getRemainingAmmo():
    return _ammo;

def fire(rowIndex, columnIndex):
    global __codeResult
    __codeResult.append(17)
    parameter.append(str(rowIndex) + "_" + str(columnIndex) + "_0")

def bomb(rowIndex, columnIndex):
    global __codeResult
    __codeResult.append(17)
    parameter.append(str(rowIndex) + "_" + str(columnIndex) + "_1")

def radar(rowIndex, columnIndex):
    global __codeResult
    __codeResult.append(17)
    parameter.append(str(rowIndex) + "_" + str(columnIndex) + "_2")

def validateFires():
    global __codeResult
    __codeResult.append(18)
    parameter.append('')

def unlock(firstDigit, secondDigit, thirdDigit, fourthDigit):
    global __codeResult
    digits = str(firstDigit) + "_" + str(secondDigit) + "_" + str(thirdDigit) + "_" + str(fourthDigit)
    __codeResult.append(11)
    parameter.append(digits)

`

    code += userCode
    await setEngine('skulpt')
    await runCode(code)
    let result = await getVariable('__codeResult')
    let parameter = await getVariable('parameter')
    result = Array.from(result)
    parameter = Array.from(parameter)
    sendMessage('Command', 'ResetState')
    for (let i = 0; i < result.length; i++) {
      console.log(methods[result[i]])
      console.log(parameter[i])
      sendMessage('Command', methods[result[i]], parameter[i])
    }
  }

  return (
    <Grid container className={classes.root} direction="row" wrap={'nowrap'}>
      <Grid
        item
        md={6}
        className={classes.editor}
        container
        direction="column"
      >
        <Header title="EDITEUR" />
        <Content>
          <Editor
            lang="python"
            onChange={onCodeChange}
            initialData={''}
            height="90%"
          />
          <Button
            onClick={validate}
            style={{ position: 'relative', left: '20%', top: '10px' }}
          >
            Valider
          </Button>
          <Button
            onClick={evaluateCode}
            style={{ position: 'relative', right: '20%', top: '10px' }}
          >
            Valider
          </Button>
        </Content>
      </Grid>
    </Grid>
  )
}

export default Python
