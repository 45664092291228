import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { Topbar } from './components'
import TopbarWithLogin from '../Main/components/Topbar'
// import VideoBackground from '../../components/BackgroundMusic/VideoBackground'

const useStyles = makeStyles(() => ({
  root: {
    // position: 'fixed',
    height: '100%',
  },
  content: {
    height: '100%',
  },
}))

const Minimal = (props) => {
  const { children } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/* {props.withLogoutBtn ? <TopbarWithLogin /> : <Topbar />} */}
      <main className={classes.content}>{children}</main>
    </div>
  )
}

Minimal.propTypes = {
  children: PropTypes.node,
  withLogoutBtn: PropTypes.bool,
  className: PropTypes.string,
}

Minimal.defaultProps = {
  withLogoutBtn: false,
}

export default Minimal
