import React from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import CustomButton from '../../../../components/CustomButton'
import { useDeleteUser } from 'domains/user/useUser'

const DeleteUserDialog: React.FC<
  DialogProps & { userId: string; onClose: () => void }
> = (props) => {
  const { onClose, userId, ...other } = props

  const [doDeleteUser, { loading: doDeleteUserLoading }] = useDeleteUser({
    onCompleted: () => onClose(),
  })
  const handleConfirmBtnClick = () => {
    doDeleteUser(userId)
  }

  return (
    <Dialog
      {...{
        ...other,
        'aria-labelledby': 'delete-user',
      }}
    >
      <DialogTitle id="delete-user" disableTypography>
        <Typography component="h2" variant="h5">
          SUPPRIMER UN UTILISATEUR
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Voulez-vous vraiment supprimer cet utilisateur ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          color="primary"
        >
          Annuler
        </Button>
        <CustomButton
          loading={doDeleteUserLoading}
          onClick={handleConfirmBtnClick}
          color="primary"
        >
          Confirmer
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteUserDialog
