import gql from 'graphql-tag'

const DETAILS = gql`
  fragment FragmentUserDetails on User {
    id
    username
    email
    roles
    firstName
    lastName
    xp
    nickname
    avatarIndex
    level
    gold
    diamond
    energy
    tip
    time
    music
    sfx
    volume
    maxExperience
    refillDate
    lives
    questsStatus {
      languageIndex
      questIndex
      isComplete
      showCompletion
      correctAnswers
      wrongAnswers
      responseTime
      score
      chapterStatuses {
        chapterIndex
        isComplete
        isUnlocked
        correctAnswers
        wrongAnswers
        responseTime
        score
      }
    }
    showFirstTutorial
    showSecondTutorial
    pythonTestDone
    showCardTutorial
    showIntroduction
    showDashboardIntroduction
    showVillageDestruction
    showLabyrinthScene
    cardData {
      cardIndex
      cardLife
      cardAttack
      cardMana
    }
    codeData {
      languageIndex
      name
      code
    }
  }
`

const LOGIN_PAYLOAD = gql`
  fragment LoginPayloadInfo on LoginPayload {
    accessToken
    tokenType
  }
`

const SIGNUP_PAYLOAD = gql`
  fragment SignupPayloadInfo on SignupPayload {
    accessToken
    user {
      ...FragmentUserDetails
    }
  }
  ${DETAILS}
`

const SSO_PAYLOAD = gql`
  fragment SsoPayloadInfo on SsoPayload {
    accessToken
    user {
      ...FragmentUserDetails
    }
  }
  ${DETAILS}
`

const fragments = {
  DETAILS,
  LOGIN_PAYLOAD,
  SIGNUP_PAYLOAD,
  SSO_PAYLOAD,
}

export default fragments
