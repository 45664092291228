import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'

interface TabDisplayProps {
  ariaLabel?: string
  tabs: {
    label: string
    content: React.ReactNode
  }[]
}

interface TabPanelProps {
  index: number
  value: number
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

const a11yTabProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

const TabDisplay: React.FC<TabDisplayProps> = (props) => {
  const { tabs, ariaLabel } = props

  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0)

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) =>
    setSelectedTabIndex(newValue)

  return (
    <Paper style={{ height: '100%' }}>
      <Tabs
        value={selectedTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label={ariaLabel}
      >
        {tabs.map((tab, i) => (
          <Tab key={`tab-${i}`} label={tab.label} {...a11yTabProps(i)} />
        ))}
      </Tabs>

      <Divider />

      {tabs.map((tab, i) => (
        <TabPanel key={`tabPanel-${i}`} value={selectedTabIndex} index={i}>
          {tab.content}
        </TabPanel>
      ))}
    </Paper>
  )
}

export default TabDisplay
